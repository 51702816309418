import request from "@/utils/request";
export function getAccessKeyList(data) {
    return request({
        url: "/user/accessKeyInfo",
        method: "GET",
        params: data
    });
}
export function createAccessKey(data) {
    return request({
        url: "/user/createAccessKey",
        method: "POST",
        data
    });
}
export function updateAccessKey(data) {
    return request({
        url: "/user/updateAccessKey",
        method: "POST",
        data
    });
}
export function downloadAccessKey(data) {
    return request({
        url: "/user/downloadAccessKey",
        method: "GET",
        params: data
    });
}
export function getBucketList(data) {
    return request({
        url: "/resource/ossBucketList",
        method: "GET",
        params: data
    });
}
export function createBucket(data) {
    return request({
        url: "/resource/createOssBucket",
        method: "POST",
        data
    });
}
export function deleteOssBucket(data) {
    return request({
        url: "/resource/deleteOssBucket",
        method: "POST",
        data
    });
}
export function deleteOssObject(data) {
    return request({
        url: "/resource/deleteOssObject",
        method: "POST",
        data
    });
}
export function getOSSObejctList(data) {
    return request({
        url: "/resource/ossObjectList",
        method: "GET",
        params: data
    });
}
export function getAccessKey(data) {
    return request({
        url: "/user/accessKeyInfo",
        method: "GET",
        params: data
    });
}
