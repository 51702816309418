<template>
  <div class="type" :class="getClass">
    <svg-icon icon-class="confirm" class-name="confirm-icon"></svg-icon>
    <span>{{ typeText[type] }}</span>
    <!-- confirm -->
  </div>
</template>
<script setup>
import { computed } from "vue";
const typeText = {
  master: "主账号",
  admin: "管理员",
  sub: "子账号"
};
const props = defineProps({
  type: {
    type: String,
    default: ""
  }
});
const getClass = computed(() =>
  props.type === "master" || props.type === "admin"
    ? "master-style"
    : "sub-style"
);
</script>
<style lang="scss" scoped>
.type {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 22px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 22px;
  color: $primary;
  background-color: $primary-primaryblue-20;
  border-radius: 11px;
}
.master-style {
  color: $brand-bob-blue-1;
  background-color: $primary-primaryblue-20;
}
.sub-style {
  color: $assist-safe-50;
  background-color: $assist-safe-10;
}
</style>
