<template>
  <el-tooltip
    v-if="text"
    v-bind="$attrs"
    :content="text"
    placement="bottom"
    effect="light"
  >
    <svg :class="svgClass" :color="color" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
  </el-tooltip>
  <svg v-else :class="svgClass" :color="color" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  iconClass: {
    type: String || undefined,
    required: true
  },
  className: {
    type: String,
    default: ""
  },
  text: {
    type: String,
    default: ""
  },
  color: {
    type: String,
    default: ""
  }
});
const iconName = computed(() => {
  return `#icon-${props.iconClass}`;
});
const svgClass = computed(() => {
  if (props.className) {
    return "svg-icon " + props.className;
  } else {
    return "svg-icon";
  }
});
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
  font-size: inherit;
}
</style>
