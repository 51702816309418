<template>
  <el-dropdown
    ref="messageDrop"
    class="dropdown_container"
    popper-class="my-popper my-dropdown-popper-arrow"
    @visible-change="getDataList"
  >
    <div
      :class="['menu-item display-flex align-items', visible ? 'active' : '']"
    >
      消息
      <div
        v-if="props.unReadCount"
        :class="['badge', props.unReadCount < 10 ? 'readCount' : '']"
      >
        {{ props.unReadCount }}
      </div>
    </div>

    <template #dropdown>
      <div class="message_container">
        <div class="top_bar">
          <div class="notify">站内消息通知</div>
          <div>
            <span class="top_bar-right ele-hover" @click="readAll"
              >全部已读</span
            >
          </div>
        </div>
        <div
          v-if="messageList.length"
          v-loading="!messageList.length"
          class="message_list"
        >
          <div
            v-for="item in messageList"
            :key="item.id"
            class="message_content"
            :style="{
              height: getActualWidthOfChars(item.title) > 100 ? '54px' : '36px'
            }"
            @click.stop="goDetail(item)"
          >
            <span class="title">
              <div class="message_icon_box">
                <svg-icon
                  class="message2-icon"
                  icon-class="message2"
                ></svg-icon>
                <div v-if="item.status === 0" class="dot"></div>
              </div>
              <span class="text">{{ item.title }}</span></span
            >
            <span class="time">{{ item.created_at }}</span>
          </div>
        </div>
        <div v-else class="no_data_box">
          <NoData icon-class="message2" />
        </div>
      </div>
      <!-- <el-button class="more" @click="seeMessage">查看全部</el-button> -->
      <div class="more ele-hover" @click="seeMessage">查看全部</div>
    </template>
  </el-dropdown>
</template>
<script setup>
import NoData from "@/components/NoData";
import { readMessage, getMessageList } from "@/service/message";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import day from "dayjs";
import { getMsg } from "@/utils";

// 路由跳转
const router = useRouter();
const messageDrop = ref();
const seeMessage = () => {
  router.push("/message/index");
  messageDrop.value.handleClose();
};
const goDetail = (row) => {
  emit("readMessage");
  router.push({ path: "/message/detail", query: { id: row.id } });
  messageDrop.value.handleClose();
};
//判断字符串长度
const getActualWidthOfChars = (text, options = {}) => {
  const { size = 14, family = "PingFang SC" } = options;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = `${size}px ${family}`;
  const metrics = ctx.measureText(text);
  const actual =
    Math.abs(metrics.actualBoundingBoxLeft) +
    Math.abs(metrics.actualBoundingBoxRight);
  return Math.max(metrics.width, actual);
};

const props = defineProps({
  unReadCount: {
    type: Number || String,
    default: 0
  }
});
const visible = ref(false);
const emit = defineEmits(["readMessage"]);
const readAll = async () => {
  await readMessage({ is_all: 1 });
  emit("readMessage");
  getDataList();
  getMsg("全部已读");

  messageDrop.value.handleClose();
};
const messageList = ref([]);
const getDataList = async (val) => {
  visible.value = val;
  const res = await getMessageList({ page_size: 5 });
  messageList.value = res.data.message_list;
  res.data.message_list.map((item) => {
    if (day(item.created_at).format("YYYY") == new Date().getFullYear()) {
      item.created_at = day(item.created_at).format("MM-DD");
    } else {
      item.created_at = day(item.created_at).format("YYYY-MM-DD");
    }
  });
};
onMounted(() => {
  getDataList();
});
</script>

<style lang="scss" scoped>
.dropdown_container {
  /* margin-left: auto; */
  line-height: normal;
}
.message_container {
  position: relative;
  width: 360px;
  height: auto;
  .top_bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 18px 24px 14px 24px;
    .notify,
    .top_bar-right {
      height: 18px;
      line-height: 18px;
    }
    .notify {
      font-weight: 600;
      color: $neutral-neutral-90;
    }
    .top_bar-right {
      color: $neutral-neutral-80;
    }
  }
  .message_list {
    // max-height: 230px;
    overflow-x: hidden;
    .message_content {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      gap: 8px;
      align-items: center;
      align-self: stretch;
      justify-content: space-between;
      height: 36px;
      margin: 0 4px;
      padding: 9px 20px;
      border-radius: 2px;
      cursor: pointer;
      /* width: 360px; */
      &:hover {
        // color: blue;
        background-color: $primary-primaryblue-15;
        border-radius: 8px;
      }
      .title {
        display: flex;
        flex-grow: 1;
        align-items: center;
        height: 36px;
        overflow: hidden;
        color: $neutral-neutral-80;
        font-size: 13px;
        .message_icon_box {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          margin-right: 8px;
          background-color: #e6f4fe;
          cursor: pointer;
          .message2-icon {
            font-size: 12px;
            color: $neutral-neutral-80;
          }
          .dot {
            position: absolute;
            top: -2px;
            right: -2px;
            width: 6px;
            height: 6px;
            background-color: $assist-danger-50;
            border-radius: 50%;
            border-radius: 50%;
          }
        }
        .sound-icon {
          flex-grow: 0;
          width: 16px;
          height: 16px;
          margin: 1px 8px 1px 0;
          padding: 1px;
          background-color: $primary-primaryblue-20;
          border-radius: 2px;
        }
        .text {
          display: -webkit-box;
          width: 100px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: $neutral-neutral-80;
        }
      }
      .time {
        overflow: hidden;
        color: $neutral-neutral-70;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .message_list > .message_content:not(:last-child) {
    margin-bottom: 4px;
  }

  .no_data_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}
.more {
  // position: absolute;
  bottom: 0;
  display: flex;
  flex-grow: 0;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: 360px;
  height: 51px;
  line-height: 51px;
  padding: 12px 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.menu-item {
  font-size: 14px;
  cursor: pointer;
  color-scheme: unset;
  color: $neutral-neutral-90;
  font-weight: 400;
  &:hover {
    color: $brand-bob-blue-1;
    font-weight: 500;
  }
}
.active {
  color: $brand-bob-blue-1;
  font-weight: 500;
}
.badge {
  width: auto;
  padding: 0 8px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  margin-left: 4px;
  border-radius: 23px;
  /* border: solid 1px $others-white; */
  background-color: $assist-danger-50;
  text-align: center;
  font-weight: 600;
  color: $others-white;
}
.badge.readCount {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 0;
}
</style>
