<template>
  <transition name="fade-transform" mode="out-in">
    <section class="app-main">
      <PageHeader />
      <router-view />
    </section>
  </transition>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import PageHeader from "./Page-Header";
import { useRouter } from "vue-router";
const router = useRouter();
const path = ref("");
const isSmallPidding = computed(() => {
  let arr = ["/dashboard/index"];
  let path = router.currentRoute.value.path;
  return arr.includes(path);
});
</script>
<style lang="scss" scoped>
.has-navbar .app-main {
  padding: 0 24px;
  height: 100vh;
}
.app-main {
  position: relative;
  height: calc(100vh - $navbar-height);
  padding: 0 $padding-right 0 $padding-left;
  overflow: auto;
  .title {
    font-size: 28px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  width: 100%;
}
.p-t-52 {
  padding-top: 52px;
}
.p-t-8 {
  padding-top: 0;
}
</style>
