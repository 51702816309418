<template>
  <div class="page-header-box">
    <div v-if="!meta.hiddenHeader" class="page-header">
      <svg-icon
        v-if="meta.back"
        icon-class="back"
        class-name="back-icon"
        @click="goBack"
      ></svg-icon>
      <slot name="header">{{ meta.title }}</slot>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
const props = defineProps({
  path: {
    type: String,
    default: ""
  }
});
const route = useRoute();
const router = useRouter();
const meta = computed(() => route.meta);
const goBack = () => {
  // if (route.meta.activeMenu) {
  //   router.push(route.meta.activeMenu);
  // } else {
  router.go(-1);
  // }
};
</script>
<style lang="scss" scoped>
.page-header-box {
  /* position: absolute;
  left: 0;
  top: 0;
  z-index: 1000; */
}
.page-header {
  display: flex;
  align-items: center;
  line-height: 32px;
  font-size: 17px;
  font-weight: 600;
  color: $neutral-neutral-90;
  padding: 4px 0 16px 0;
  .back-icon {
    margin-right: 4px;
    font-size: 17px;
    cursor: pointer;
  }
}
</style>
