<template>
  <div v-if="visible" class="loading-wrap">
    <div v-if="modal" class="loading-mask"></div>
    <!-- 遮罩层 -->
    <div class="loading-box">
      <div v-if="text !== ''" :class="{ 'white-text': modal }" class="text">
        {{ text }}
      </div>
      <div class="loading-dots">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
//通过设置element-loading-text来配置文案

export default {
  data() {
    return {
      visible: false,
      text: "正在自动优化内容",
      modal: true
    };
  }
};
</script>

<style lang="scss" scoped>
.loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none; /* 避免遮罩层影响下方内容的点击 */
}

.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25); /* 半透明的黑色遮罩 */
  pointer-events: all; /* 确保遮罩层捕获点击事件，防止点击穿透 */
}

.loading-box {
  z-index: 1001;
  white-space: nowrap;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  color: $neutral-neutral-90;
  .text {
    font-size: 16px;
  }
  /* .white-text {
    color: #fff;
  } */
}

.loading-dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 44px;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: $brand-bob-blue-1;
  border-radius: 50%;
  animation: bounce 0.8s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
  background-color: $brand-bobblue-2;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
  background-color: $brand-bobgreen-1;
}

.dot:nth-child(4) {
  animation-delay: 0.3s;
  background-color: $brand-bobgreen-2;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
</style>
