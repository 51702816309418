import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/layout/index.vue";
import About from "@/components/about.vue";
export const constantRoutes = [
    {
        path: "/login",
        name: "login",
        hidden: true,
        component: () => import("@/views/login/login.vue")
    },
    {
        path: "/about",
        name: "about",
        hidden: true,
        component: About
    },
    {
        path: "/modifyPassword",
        name: "modifyPassword",
        hidden: true,
        component: () => import("@/views/modifyPassword/index.vue")
    }
];
export const errorRoutes = [
    {
        path: "/404",
        name: "404",
        hidden: true,
        component: () => import("@/views/error/404.vue")
    },
    {
        path: "/:catchAll(.*)*",
        name: "NotFound",
        redirect: "/404"
    }
];
export const asyncRoutes = [
    {
        path: "/",
        name: "index",
        hidden: true,
        redirect: (to) => {
            console.log(to);
            const role = JSON.parse(localStorage.getItem("Roles") || "")[0];
            if (role === "master") {
                return { path: "/dashboard/index" };
            }
            else if (role === "sub") {
                return { path: "/resource/machine/index" };
            }
            else {
                return { path: "/admin/index" };
            }
        }
    },
    {
        path: "/dashboard",
        component: Layout,
        redirect: "/dashboard/index",
        name: "dashboard",
        hidden: true,
        meta: {
            title: "控制台",
            roles: ["admin", "master", "sub"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/dashboard/index.vue"),
                name: "dashboardIndex",
                meta: {
                    title: "控制台",
                    hiddenHeader: true,
                    roles: ["admin", "master", "sub"],
                    breadcrumb: false
                }
            }
        ]
    },
    {
        path: "/resource",
        component: Layout,
        redirect: "/resource/machine",
        name: "resource",
        meta: {
            title: "资源管理",
            icon: "resource-off",
            activeIcon: "resource-on",
            roles: ["master", "sub"]
        },
        children: [
            {
                path: "machine",
                redirect: "/machine/index",
                name: "machine",
                meta: {
                    title: "GPU裸金属实例",
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "index",
                        component: () => import("@/views/resource/machine/index.vue"),
                        name: "machine-index",
                        meta: {
                            title: "GPU裸金属实例",
                            roles: ["master", "sub"],
                            breadcrumb: false
                        }
                    },
                    {
                        path: "add",
                        component: () => import("@/views/resource/machine/add.vue"),
                        name: "machineAdd",
                        hidden: true,
                        meta: {
                            title: "创建GPU裸金属实例",
                            back: true,
                            activeMenu: "/resource/machine/index",
                            activiteMenuTitle: "GPU裸金属实例",
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "detail",
                        component: () => import("@/views/resource/machine/detail.vue"),
                        name: "machineDetail",
                        hidden: true,
                        meta: {
                            title: "资源详情",
                            back: true,
                            activeMenu: "/resource/machine/index",
                            activiteMenuTitle: "GPU裸金属实例",
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "monitor",
                        component: () => import("@/views/monitor/index.vue"),
                        name: "monitor",
                        hidden: true,
                        meta: {
                            title: "监控",
                            back: true,
                            icon: "monitor",
                            roles: ["master", "sub"]
                        }
                    }
                ]
            },
            {
                path: "container",
                redirect: "/container/index",
                name: "container",
                meta: {
                    title: "GPU弹性实例",
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "index",
                        component: () => import("@/views/resource/container/index.vue"),
                        name: "containerList",
                        meta: {
                            title: "GPU弹性实例",
                            roles: ["master", "sub"],
                            breadcrumb: false
                        }
                    },
                    {
                        path: "monitor",
                        component: () => import("@/views/resource/container/monitor.vue"),
                        name: "container-monitor",
                        hidden: true,
                        meta: {
                            title: "监控",
                            back: true,
                            activeMenu: "/resource/container/index",
                            activiteMenuTitle: "GPU弹性实例",
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "add",
                        component: () => import("@/views/resource/container/add.vue"),
                        name: "container-add",
                        hidden: true,
                        meta: {
                            title: "创建GPU弹性实例",
                            back: true,
                            activeMenu: "/resource/container/index",
                            activiteMenuTitle: "GPU弹性实例",
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "detail",
                        component: () => import("@/views/resource/container/detail.vue"),
                        name: "container-detail",
                        hidden: true,
                        meta: {
                            title: "资源详情",
                            back: true,
                            activeMenu: "/resource/container/index",
                            activiteMenuTitle: "GPU弹性实例",
                            roles: ["master", "sub"]
                        }
                    }
                ]
            },
            //向量数据库入口临时下线
            // {
            //   path: "vector",
            //   component: () => import("@/views/vector/index"),
            //   name: "vector",
            //   meta: {
            //     title: "向量数据库",
            //     hiddenHeader: true,
            //     roles: ["master", "sub"]
            //   }
            // },
            {
                path: "storage",
                component: () => import("@/views/shareStorage/index.vue"),
                name: "shareStorage",
                meta: {
                    title: "并行文件存储EPFS",
                    roles: ["master"]
                }
            },
            {
                path: "objectStorage",
                component: () => import("@/views/objectStorage/objectStorage.vue"),
                name: "objectStorage",
                meta: {
                    title: "对象存储EOS",
                    roles: ["master", "sub"]
                }
            },
            {
                path: "detail",
                component: () => import("@/views/objectStorage/objectStorageDetail.vue"),
                name: "objectStorageDetail",
                hidden: true,
                meta: {
                    title: "存储桶详情",
                    back: true,
                    activiteMenuTitle: "对象存储",
                    roles: ["master", "sub"]
                }
            },
            {
                path: "mirror",
                redirect: "/mirror/index",
                name: "mirror",
                meta: {
                    title: "镜像",
                    roles: ["master"]
                },
                children: [
                    {
                        path: "index",
                        component: () => import("@/views/mirror/index.vue"),
                        name: "mirror-index",
                        meta: {
                            title: "镜像",
                            roles: ["master"],
                            breadcrumb: false
                        }
                    },
                    {
                        path: "create",
                        component: () => import("@/views/mirror/create.vue"),
                        name: "mirror-create",
                        hidden: true,
                        meta: {
                            title: "构建镜像",
                            back: true,
                            activeMenu: "/resource/mirror/index",
                            activiteMenuTitle: "镜像",
                            roles: ["master"]
                        }
                    },
                    {
                        path: "rebuild",
                        component: () => import("@/views/mirror/create.vue"),
                        name: "mirror-rebuild",
                        hidden: true,
                        meta: {
                            title: "构建镜像",
                            back: true,
                            activeMenu: "/resource/mirror/index",
                            activiteMenuTitle: "镜像",
                            roles: ["master"]
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/order",
        component: Layout,
        redirect: "/order/machine",
        name: "order",
        meta: {
            title: "申请记录",
            icon: "order-off",
            activeIcon: "order-on",
            roles: ["master"]
        },
        children: [
            {
                path: "machine",
                component: () => import("@/views/order/machine.vue"),
                name: "orderMachine",
                meta: {
                    title: "GPU裸金属实例",
                    roles: ["master"]
                }
            },
            {
                path: "container",
                component: () => import("@/views/order/container.vue"),
                name: "orderContainer",
                meta: {
                    title: "GPU弹性实例",
                    roles: ["master"]
                }
            },
            {
                path: "machineDetail",
                component: () => import("@/views/order/machineDetail.vue"),
                name: "orderMachineDetail",
                hidden: true,
                meta: {
                    title: "工单详情（GPU裸金属实例）",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/order/machine",
                    activiteMenuTitle: "GPU裸金属实例"
                }
            },
            {
                path: "containerDetail",
                component: () => import("@/views/order/containerDetail.vue"),
                name: "orderMcontainerDetail",
                hidden: true,
                meta: {
                    title: "工单详情（GPU弹性实例）",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/order/container",
                    activiteMenuTitle: "GPU弹性实例"
                }
            }
            // {
            //   path: "quota",
            //   component: () => import("@/views/order/quota.vue"),
            //   name: "order-quota",
            //   meta: {
            //     title: "配额操作记录",
            //     roles: ["master"]
            //   }
            // }
        ]
    },
    {
        path: "/quota",
        component: Layout,
        redirect: "/quota/index",
        name: "quota",
        hidden: true,
        meta: {
            title: "配额管理",
            roles: ["master"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/quota/index.vue"),
                name: "quota-index",
                meta: {
                    title: "配额管理",
                    icon: "quota-off",
                    activeIcon: "quota-on",
                    roles: ["master"]
                }
            },
            {
                path: "history",
                component: () => import("@/views/order/quota.vue"),
                name: "order-quota",
                meta: {
                    title: "申请历史",
                    back: true,
                    activeMenu: "/quota/index",
                    activiteMenuTitle: "配额管理",
                    roles: ["master"]
                }
            },
            {
                path: "add",
                component: () => import("@/views/quota/add.vue"),
                name: "quota-add",
                hidden: true,
                meta: {
                    title: "GPU弹性实例配额申请",
                    icon: "market-off",
                    back: true,
                    activeIcon: "market-on",
                    roles: ["master"]
                }
            }
        ]
    },
    {
        path: "/data-module",
        component: Layout,
        redirect: "/data-module/market",
        name: "data-module",
        meta: {
            title: "数据集中心",
            icon: "data-off",
            activeIcon: "data-on",
            roles: ["master", "sub"]
        },
        children: [
            {
                path: "mine",
                component: () => import("@/views/data/mine.vue"),
                name: "data-mine",
                meta: {
                    title: "我的数据集",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "market",
                component: () => import("@/views/data/market.vue"),
                name: "data-market",
                meta: {
                    title: "数据集广场",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "detail/:id",
                component: () => import("@/views/data/dataInfo.vue"),
                name: "data-detail",
                hidden: true,
                meta: {
                    title: "数据集详情",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "introduce",
                        hidden: true,
                        component: () => import("@/views/data/components/introduce.vue"),
                        name: "data-introduce",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "document",
                        hidden: true,
                        component: () => import("@/pages/code/FileListPage.vue"),
                        name: "data-document",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "setup",
                        hidden: true,
                        component: () => import("@/views/data/components/setup.vue"),
                        name: "data-setup",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    }
                ]
            },
            {
                path: "create",
                component: () => import("@/views/data/create.vue"),
                name: "data-create",
                hidden: true,
                meta: {
                    title: "创建数据集",
                    back: true,
                    activeMenu: "/data-module/mine",
                    activiteMenuTitle: "我的数据集",
                    roles: ["master", "sub"]
                }
            }
            // {
            //   path: "process",
            //   component: () => import("@/views/data/process"),
            //   name: "data-process",
            //   meta: {
            //     title: "数据加工",
            //     roles: ["master", "sub"]
            //   }
            // }
        ]
    },
    {
        path: "/model",
        component: Layout,
        redirect: "/model/market",
        name: "model",
        meta: {
            title: "模型中心",
            icon: "model-off",
            activeIcon: "model-on",
            roles: ["master", "sub"]
        },
        children: [
            {
                path: "mine",
                component: () => import("@/views/model/mine.vue"),
                name: "model-mine",
                meta: {
                    title: "我的模型",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "market",
                component: () => import("@/views/model/market.vue"),
                name: "model-market",
                meta: {
                    title: "模型广场",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "detail/:id",
                component: () => import("@/views/model/modelInfo.vue"),
                name: "model-detail",
                hidden: true,
                meta: {
                    title: "模型详情",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "introduce",
                        hidden: true,
                        component: () => import("@/views/model/components/introduce.vue"),
                        name: "model-introduce",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "document",
                        hidden: true,
                        component: () => import("@/pages/code/FileListPage.vue"),
                        name: "model-document",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "setup",
                        hidden: true,
                        component: () => import("@/views/model/components/setup.vue"),
                        name: "model-setup",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    }
                ]
            },
            {
                path: "create",
                component: () => import("@/views/model/create.vue"),
                name: "model-create",
                hidden: true,
                meta: {
                    title: "创建模型",
                    back: true,
                    activeMenu: "/model/mine",
                    activiteMenuTitle: "我的模型",
                    roles: ["master", "sub"]
                }
            }
        ]
    },
    {
        path: "/train",
        component: Layout,
        redirect: "/train/model",
        name: "train",
        meta: {
            title: "算法中心",
            icon: "train-off",
            activeIcon: "train-on",
            roles: ["master", "sub"]
        },
        children: [
            {
                path: "myCode",
                component: () => import("@/views/train/myCode.vue"),
                name: "train-my-code",
                meta: {
                    title: "我的算法",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "create",
                component: () => import("@/views/train/create.vue"),
                name: "trainCreate",
                hidden: true,
                meta: {
                    title: "创建算法",
                    back: true,
                    activeMenu: "/train/myCode",
                    activiteMenuTitle: "我的算法",
                    roles: ["master", "sub"]
                }
            },
            {
                path: "codeMarket",
                component: () => import("@/views/train/codeMarket.vue"),
                name: "codeMarket",
                meta: {
                    title: "算法广场",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "codeDetail/:id",
                component: () => import("@/views/train/codeDetail.vue"),
                name: "train-code-detail",
                hidden: true,
                meta: {
                    title: "算法详情",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "introduce",
                        hidden: true,
                        component: () => import("@/views/train/components/introduce.vue"),
                        name: "train-code-introduce",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "document",
                        hidden: true,
                        component: () => import("@/pages/code/FileListPage.vue"),
                        name: "train-code-document",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "setup",
                        hidden: true,
                        component: () => import("@/views/train/components/setup.vue"),
                        name: "train-code-setup",
                        meta: {
                            hiddenHeader: true,
                            roles: ["master", "sub"]
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/task",
        component: Layout,
        redirect: "/task/taskModeling",
        name: "task",
        meta: {
            title: "训练开发",
            icon: "train-off",
            activeIcon: "train-on",
            roles: ["master"]
        },
        children: [
            {
                path: "taskModeling",
                component: () => import("@/views/train/taskModeling.vue"),
                name: "taskModeling",
                meta: {
                    title: "训练开发",
                    icon: "space-off",
                    activeIcon: "space-on",
                    roles: ["master"],
                    breadcrumb: false
                }
            },
            {
                path: "add",
                component: () => import("@/views/train/add.vue"),
                name: "taskAdd",
                hidden: true,
                meta: {
                    title: "创建任务",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/task/taskModeling"
                }
            },
            {
                path: "detail",
                component: () => import("@/views/train/taskDetail.vue"),
                name: "taskDetail",
                hidden: true,
                meta: {
                    title: "任务式建模详情",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/task/taskModeling"
                }
            }
        ]
    },
    {
        path: "/inference",
        component: Layout,
        redirect: "/inference/onllineService",
        name: "inference",
        meta: {
            title: "服务部署",
            roles: ["master"],
            icon: "serve-off",
            activeIcon: "serve-on"
        },
        children: [
            {
                path: "onllineService",
                component: () => import("@/views/inference/service.vue"),
                redirect: "/inference/onllineService/market",
                name: "onllineService",
                hidden: true,
                meta: {
                    title: "服务部署",
                    roles: ["master"],
                    breadcrumb: false,
                    hiddenHeader: true
                },
                children: [
                    {
                        path: "market",
                        component: () => import("@/views/inference/market.vue"),
                        name: "service-market",
                        hidden: true,
                        meta: {
                            title: "服务广场",
                            roles: ["master"],
                            back: true,
                            activeMenu: "/inference/onllineService"
                        }
                    },
                    {
                        path: "mine",
                        component: () => import("@/views/inference/onllineService.vue"),
                        name: "service-mine",
                        hidden: true,
                        meta: {
                            title: "我的服务",
                            roles: ["master"],
                            back: true,
                            activeMenu: "/inference/onllineService"
                        }
                    }
                ]
            },
            {
                path: "add",
                component: () => import("@/views/inference/add.vue"),
                name: "serviceAdd",
                hidden: true,
                meta: {
                    title: "创建服务",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/inference/onllineService"
                }
            },
            {
                path: "edit",
                component: () => import("@/views/inference/edit.vue"),
                name: "serviceEdit",
                hidden: true,
                meta: {
                    title: "更新服务",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/inference/onllineService"
                }
            },
            {
                path: "detail",
                component: () => import("@/views/inference/serviceDetail.vue"),
                name: "serviceDetail",
                hidden: true,
                meta: {
                    title: "服务详情",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/inference/onllineService",
                    activiteMenuTitle: "我的服务"
                }
            },
            {
                path: "market-detail",
                component: () => import("@/views/inference/marketDetail.vue"),
                name: "market-detail",
                hidden: true,
                meta: {
                    title: "服务详情",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/inference/onllineService",
                    activiteMenuTitle: "服务广场"
                }
            },
            {
                path: "persetDetail",
                component: () => import("@/views/inference/components/persetDetail.vue"),
                name: "persetDetail",
                hidden: true,
                meta: {
                    title: "服务详情",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/inference/onllineService"
                }
            }
        ]
    },
    {
        path: "/application",
        component: Layout,
        redirect: "/application/knowledge",
        name: "application-center",
        meta: {
            title: "应用中心",
            icon: "appliance-off",
            activeIcon: "appliance-on",
            roles: ["master", "sub"]
        },
        children: [
            {
                path: "mine",
                component: () => import("@/views/application/mine.vue"),
                name: "application-mine",
                meta: {
                    title: "我的应用",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                },
                children: []
            },
            {
                path: "market",
                component: () => import("@/views/application/market.vue"),
                name: "application-market",
                meta: {
                    title: "应用广场",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "knowledge",
                redirect: "/application/knowledge/index",
                name: "knowledge",
                meta: {
                    title: "知识库",
                    roles: ["master", "sub"]
                },
                children: [
                    {
                        path: "index",
                        component: () => import("@/views/knowledge/index.vue"),
                        name: "knowledge-index",
                        hidden: true,
                        meta: {
                            title: "知识库",
                            roles: ["master", "sub"],
                            breadcrumb: false
                        }
                    },
                    {
                        path: "add",
                        component: () => import("@/views/knowledge/add.vue"),
                        name: "knowledge-add",
                        hidden: true,
                        meta: {
                            title: "创建知识库",
                            back: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "edit",
                        component: () => import("@/views/knowledge/edit.vue"),
                        name: "knowledge-edit",
                        hidden: true,
                        meta: {
                            title: "编辑知识库",
                            back: true,
                            roles: ["master", "sub"]
                        }
                    },
                    {
                        path: "detail",
                        redirect: "/detail/:id",
                        name: "knowledge-detail",
                        hidden: true,
                        meta: {
                            title: "知识库详情",
                            roles: ["master", "sub"]
                        },
                        children: [
                            {
                                path: ":id",
                                component: () => import("@/views/knowledge/detail.vue"),
                                name: "knowledge-detail-index",
                                hidden: true,
                                meta: {
                                    title: "知识库详情",
                                    back: true,
                                    breadcrumb: false,
                                    roles: ["master", "sub"]
                                }
                            },
                            {
                                path: ":id/fileSlices",
                                component: () => import("@/views/knowledge/fileSlices.vue"),
                                name: "file-slices",
                                hidden: true,
                                meta: {
                                    title: "查看文件",
                                    back: true,
                                    roles: ["master", "sub"]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: "experience",
                component: () => import("@/views/application/experience.vue"),
                name: "application-experience",
                hidden: true,
                meta: {
                    title: "应用体验",
                    roles: ["master", "sub"],
                    hiddenHeader: true
                }
            },
            {
                path: "add",
                component: () => import("@/views/application/add.vue"),
                name: "application-add",
                hidden: true,
                meta: {
                    title: "新增应用",
                    hiddenHeader: true,
                    roles: ["master", "sub"]
                }
            }
        ]
    },
    //入口下线 2024-6-28
    // {
    //   path: "/space",
    //   component: Layout,
    //   redirect: "/space/market",
    //   name: "demo",
    //   meta: {
    //     title: "交互空间",
    //     icon: "space-off",
    //     activeIcon: "space-on",
    //     roles: ["master", "sub"]
    //   },
    //   children: [
    //     {
    //       path: "mine",
    //       component: () => import("@/views/demo/mine"),
    //       name: "demo-mine",
    //       meta: {
    //         title: "我的交互空间",
    //         hiddenHeader: true,
    //         roles: ["master", "sub"]
    //       }
    //     },
    //     {
    //       path: "market",
    //       component: () => import("@/views/demo/market"),
    //       name: "demo-market",
    //       meta: {
    //         title: "交互空间广场",
    //         hiddenHeader: true,
    //         roles: ["master", "sub"]
    //       }
    //     },
    //     {
    //       path: "detail/:id",
    //       component: () => import("@/views/demo/demoInfo"),
    //       name: "demo-detail",
    //       hidden: true,
    //       meta: {
    //         title: "交互空间详情",
    //         hiddenHeader: true,
    //         roles: ["master", "sub"]
    //       },
    //       children: [
    //         {
    //           path: "app",
    //           hidden: true,
    //           component: () => import("@/views/demo/components/iframe"),
    //           name: "demo-app",
    //           meta: {
    //             hiddenHeader: true,
    //             roles: ["master", "sub"]
    //           }
    //         },
    //         {
    //           path: "introduce",
    //           hidden: true,
    //           component: () => import("@/views/demo/components/introduce"),
    //           name: "demo-introduce",
    //           meta: {
    //             hiddenHeader: true,
    //             roles: ["master", "sub"]
    //           }
    //         },
    //         {
    //           path: "document",
    //           hidden: true,
    //           component: () => import("@/pages/code/FileListPage.vue"),
    //           name: "demo-document",
    //           meta: {
    //             hiddenHeader: true,
    //             roles: ["master", "sub"]
    //           }
    //         },
    //         {
    //           path: "setup",
    //           hidden: true,
    //           component: () => import("@/views/demo/components/setup"),
    //           name: "demo-setup",
    //           meta: {
    //             hiddenHeader: true,
    //             roles: ["master", "sub"]
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       path: "create",
    //       component: () => import("@/views/demo/create"),
    //       name: "demo-create",
    //       hidden: true,
    //       meta: {
    //         title: "创建交互空间",
    //         back: true,
    //         activeMenu: "/demo/mine",
    //         activiteMenuTitle: "我的交互空间",
    //         roles: ["master", "sub"]
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: "/market",
    //   component: Layout,
    //   redirect: "/appMarket/index",
    //   name: "appMarket",
    //   meta: {
    //     title: "应用市场",
    //     roles: ["master", "sub"]
    //   },
    //   children: [
    //     {
    //       path: "index",
    //       component: () => import("@/views/appMarket/index"),
    //       name: "app-market-index",
    //       meta: {
    //         title: "应用市场",
    //         icon: "market-off",
    //         activeIcon: "market-on",
    //         roles: ["master", "sub"]
    //       }
    //     }
    //   ]
    // },
    {
        path: "/finance",
        component: Layout,
        redirect: "/finance/expense",
        name: "finance",
        hidden: true,
        meta: {
            title: "费用账单",
            icon: "finance-off",
            activeIcon: "finance-on",
            roles: ["master"]
        },
        children: [
            {
                path: "overview",
                component: () => import("@/views/finance/overview.vue"),
                name: "overview",
                meta: {
                    title: "费用概览",
                    roles: ["master"]
                }
            },
            {
                path: "expense",
                component: () => import("@/views/finance/expense.vue"),
                name: "expense",
                meta: {
                    title: "账单明细",
                    roles: ["master"]
                }
            },
            // {
            //   path: "officialPrice",
            //   component: () => import("@/views/finance/officialPrice"),
            //   name: "officialPrice",
            //   meta: {
            //     title: "官网价",
            //     roles: ["master"]
            //   }
            // },
            {
                path: "coupon",
                component: () => import("@/views/finance/coupon.vue"),
                name: "coupon",
                meta: {
                    title: "优惠券",
                    roles: ["master"]
                }
            },
            {
                path: "recharge",
                component: () => import("@/views/finance/recharge.vue"),
                name: "recharge",
                hidden: true,
                meta: {
                    title: "充值汇款",
                    back: true,
                    roles: ["master"]
                }
            },
            {
                path: "records",
                component: () => import("@/views/finance/records.vue"),
                name: "records",
                hidden: true,
                meta: {
                    title: "对公汇款记录",
                    back: true,
                    roles: ["master"]
                }
            },
            {
                path: "incomeExpenditure",
                component: () => import("@/views/finance/incomeExpenditure.vue"),
                name: "incomeExpenditure",
                hidden: true,
                meta: {
                    title: "收支明细",
                    back: true,
                    roles: ["master"]
                }
            }
        ]
    },
    {
        path: "/user",
        component: Layout,
        redirect: "/user/index",
        name: "user",
        meta: {
            title: "访问控制",
            icon: "user-off",
            activeIcon: "user-on",
            roles: ["master"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/user/index.vue"),
                name: "userList",
                meta: {
                    title: "用户",
                    roles: ["master"]
                }
            },
            {
                path: "add",
                component: () => import("@/views/user/add.vue"),
                name: "addUser",
                hidden: true,
                meta: {
                    title: "创建用户",
                    back: true,
                    activeMenu: "/user/index",
                    activiteMenuTitle: "用户",
                    roles: ["master", "sub"]
                }
            },
            {
                path: "detail",
                component: () => import("@/views/user/detail.vue"),
                name: "userDetail",
                hidden: true,
                meta: {
                    title: "用户详情",
                    back: true,
                    activeMenu: "/user/index",
                    activiteMenuTitle: "用户",
                    roles: ["master"]
                }
            },
            {
                path: "userGroupIndex",
                component: () => import("@/views/userGroup/index.vue"),
                name: "userGroupList",
                meta: {
                    title: "用户组",
                    roles: ["master"]
                }
            },
            {
                path: "userGroupAdd",
                component: () => import("@/views/userGroup/add.vue"),
                name: "addUserGroup",
                hidden: true,
                meta: {
                    title: "创建用户组",
                    back: true,
                    activeMenu: "/user/userGroupIndex",
                    activiteMenuTitle: "用户组",
                    roles: ["master"]
                }
            },
            {
                path: "userGroupDetail",
                component: () => import("@/views/userGroup/detail.vue"),
                name: "userGroupDetail",
                hidden: true,
                meta: {
                    title: "用户组详情",
                    back: true,
                    activeMenu: "/user/userGroupIndex",
                    activiteMenuTitle: "用户组",
                    roles: ["master"]
                }
            },
            {
                path: "secretKey",
                component: () => import("@/views/objectStorage/secretKey.vue"),
                name: "secretKey",
                meta: {
                    title: "访问密钥",
                    roles: ["master"]
                }
            },
            {
                path: "role",
                component: () => import("@/views/permission/role.vue"),
                name: "role",
                meta: {
                    title: "角色管理",
                    roles: ["master"]
                }
            },
            {
                path: "createRole",
                component: () => import("@/views/permission/roleSetting.vue"),
                name: "createRole",
                hidden: true,
                meta: {
                    title: "创建角色",
                    back: true,
                    activeMenu: "/user/role",
                    activiteMenuTitle: "角色管理",
                    roles: ["master"]
                }
            },
            {
                path: "updateRole",
                component: () => import("@/views/permission/roleSetting.vue"),
                name: "updateRole",
                hidden: true,
                meta: {
                    title: "编辑角色",
                    back: true,
                    activeMenu: "/user/role",
                    activiteMenuTitle: "角色管理",
                    roles: ["master"]
                }
            },
            {
                path: "permission",
                component: () => import("@/views/permission/index.vue"),
                name: "permission",
                meta: {
                    title: "权限管理",
                    roles: ["master"]
                }
            }
        ]
    },
    {
        path: "/personal",
        component: Layout,
        redirect: "/personal/index",
        name: "personal",
        hidden: true,
        meta: {
            title: "个人中心",
            roles: ["admin", "master", "sub"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/personal/index.vue"),
                name: "personal",
                meta: {
                    title: "个人中心",
                    roles: ["admin", "master", "sub"]
                }
            },
            {
                path: "token",
                component: () => import("@/views/personal/token.vue"),
                name: "token",
                meta: {
                    title: "我的Token",
                    back: true,
                    roles: ["master", "sub"]
                }
            },
            {
                path: "verify",
                component: () => import("@/views/personal/verify.vue"),
                name: "verify",
                hidden: true,
                meta: {
                    title: "企业认证",
                    roles: ["master"],
                    back: true,
                    activeMenu: "/personal/index"
                }
            }
        ]
    },
    {
        path: "/register-approval",
        component: Layout,
        redirect: "/approval/register",
        name: "register-approval",
        meta: {
            title: "审核",
            roles: ["admin"],
            icon: "approval-off",
            activeIcon: "approval-on"
        },
        children: [
            {
                path: "register",
                component: () => import("@/views/approval/register.vue"),
                name: "register-approval",
                meta: {
                    title: "注册审核",
                    roles: ["admin"]
                }
            },
            {
                path: "resource",
                component: () => import("@/views/approval/resource.vue"),
                name: "resource-approval",
                meta: {
                    title: "资源审核",
                    roles: ["admin"]
                }
            },
            {
                path: "quota",
                component: () => import("@/views/approval/quota.vue"),
                name: "quota-approval",
                meta: {
                    title: "配额审核",
                    roles: ["admin"]
                }
            },
            {
                path: "firmVerify",
                component: () => import("@/views/approval/firmVerify.vue"),
                name: "firmVerify-approval",
                meta: {
                    title: "企业认证审核",
                    roles: ["admin"]
                }
            }
        ]
    },
    // {
    //   path: "/userConfig",
    //   component: Layout,
    //   redirect: "/userConfig/index",
    //   name: "userConfig",
    //   meta: {
    //     title: "账户配置",
    //     roles: ["admin"]
    //   },
    //   children: [
    //     {
    //       path: "index",
    //       component: () => import("@/views/userConfig/index"),
    //       name: "user-config",
    //       meta: {
    //         title: "账户配置",
    //         icon: "user-off",
    //         activeIcon: "user-on",
    //         roles: ["admin"]
    //       }
    //     }
    //   ]
    // },
    {
        path: "/admin",
        component: Layout,
        redirect: "/admin/index",
        name: "admin",
        meta: {
            title: "用户",
            roles: ["admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/admin/index.vue"),
                name: "admin",
                meta: {
                    title: "用户",
                    icon: "user-off",
                    activeIcon: "user-on",
                    roles: ["admin"]
                }
            },
            {
                path: "detail",
                component: () => import("@/views/admin/detail.vue"),
                name: "adminDetail",
                hidden: true,
                meta: {
                    title: "用户详情",
                    back: true,
                    activeMenu: "/admin/index",
                    roles: ["admin"]
                }
            }
        ]
    },
    {
        path: "/quotaSetting",
        component: Layout,
        redirect: "/quotaSetting/index",
        name: "quotaSetting",
        meta: {
            title: "配额组设置",
            roles: ["admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/quotaSetting/index.vue"),
                name: "quotaSetting",
                meta: {
                    title: "配额组设置",
                    icon: "user-off",
                    activeIcon: "user-on",
                    roles: ["admin"]
                }
            },
            {
                path: "add",
                component: () => import("@/views/quotaSetting/modifyQuotaSetting.vue"),
                name: "addQuotaSetting",
                hidden: true,
                meta: {
                    title: "创建配额组",
                    icon: "user-off",
                    activeIcon: "user-on",
                    roles: ["admin"]
                }
            },
            {
                path: "edit",
                component: () => import("@/views/quotaSetting/modifyQuotaSetting.vue"),
                name: "editQuotaSetting",
                hidden: true,
                meta: {
                    title: "编辑配额组",
                    back: true,
                    icon: "user-off",
                    activeMenu: "/quotaSetting/index",
                    activeIcon: "user-on",
                    roles: ["admin"]
                }
            },
            {
                path: "detail",
                component: () => import("@/views/quotaSetting/quotaSettingDetail.vue"),
                name: "quotaSettingDetail",
                hidden: true,
                meta: {
                    title: "配额组详情",
                    activeMenu: "/quotaSetting/index",
                    back: true,
                    roles: ["admin"]
                }
            },
            {
                path: "member",
                component: () => import("@/views/quotaSetting/memberManager.vue"),
                name: "memberManager",
                hidden: true,
                meta: {
                    title: "成员管理",
                    back: true,
                    activeMenu: "/quotaSetting/index",
                    roles: ["admin"]
                }
            }
        ]
    },
    {
        path: "/chat",
        component: Layout,
        redirect: "/chat/index",
        name: "Chat",
        hidden: true,
        meta: {
            title: "Chat",
            roles: ["admin", "master", "sub"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/chat/index.vue"),
                name: "chatIndex",
                // isOpen: true,
                meta: {
                    title: "Chat",
                    icon: "chat",
                    roles: ["admin", "master", "sub"]
                }
            }
        ]
    },
    {
        path: "/workbench",
        name: "workbench",
        isOpen: true,
        hidden: true,
        component: () => import("@/views/workbench/index.vue"),
        meta: {
            roles: ["master", "sub"]
        }
    },
    {
        path: "/message",
        component: Layout,
        redirect: "/message/index",
        hidden: true,
        name: "message",
        meta: {
            title: "消息列表",
            roles: ["admin", "master", "sub"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/message/index.vue"),
                name: "messageList",
                meta: {
                    title: "消息列表",
                    icon: "message",
                    roles: ["admin", "master", "sub"],
                    breadcrumb: false
                }
            },
            {
                path: "detail",
                component: () => import("@/views/message/detail.vue"),
                name: "messageDetail",
                meta: {
                    title: "消息详情",
                    hiddenHeader: true,
                    icon: "messageDetail",
                    roles: ["admin", "master", "sub"]
                }
            }
        ]
    },
    {
        path: "/logs",
        component: Layout,
        redirect: "/logs/index",
        name: "logs",
        meta: {
            title: "操作日志",
            roles: ["master", "admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/logs/index.vue"),
                name: "logIndex",
                meta: {
                    title: "操作日志",
                    roles: ["master", "admin"],
                    icon: "log-off",
                    activeIcon: "log-on",
                    breadcrumb: false
                }
            }
        ]
    },
    {
        path: "/clusterMonitor",
        component: Layout,
        redirect: "/clusterMonitor/index",
        name: "clusterMonitor",
        meta: {
            title: "集群监控",
            roles: ["admin"]
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/clusterMonitor/index.vue"),
                name: "clusterMonitor",
                meta: {
                    title: "集群监控",
                    icon: "user-off",
                    activeIcon: "user-on",
                    roles: ["admin"]
                }
            }
        ]
    },
    {
        path: "/account",
        component: Layout,
        redirect: "/account/trade",
        name: "account",
        meta: {
            title: "交易查询",
            roles: ["admin"],
            icon: "jiaoyichaxun-off",
            activeIcon: "jiaoyichaxun-on"
        },
        children: [
            {
                path: "trade",
                component: () => import("@/views/account/trade.vue"),
                name: "account-trade",
                meta: {
                    title: "交易查询",
                    icon: "jiaoyichaxun-off",
                    activeIcon: "jiaoyichaxun-on",
                    roles: ["admin"]
                }
            }
        ]
    }
];
const router = createRouter({
    // history: createWebHistory(process.env.VUE_APP_BASE_URL),
    history: createWebHistory(),
    routes: constantRoutes
});
export function resetRouter() {
    const routers = router.getRoutes();
    routers.map((it) => {
        if (!["login", "about", "modifyPassword"].includes(it.name)) {
            router.removeRoute(it.name);
        }
    });
    console.log(router.getRoutes());
}
export default router;
