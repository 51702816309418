<template>
  <div class="navbar">
    <div class="login-img" @click="goPortal">
      <img :src="logo" alt="" />
    </div>
    <Breadcrumb />
    <div class="home-box m-l-auto display-flex align-items">
      <!-- <div class="experience" @click="ebChatOpen">
        <svg-icon
          icon-class="star"
          class="start-star-icon star-icon"
        ></svg-icon>
        <span class="text-animation">体验中心</span>
        <svg-icon icon-class="star" class="end-star-icon star-icon"></svg-icon>
      </div> -->
      <div v-if="userInfo.type === 'master'" class="menu-item" @click="goHome">
        控制台
      </div>
      <div
        v-if="userInfo.type === 'master'"
        class="drop-container"
        @mouseenter="showCost = true"
        @mouseleave="showCost = false"
      >
        <div class="menu-item h-100">费用</div>
        <!-- <transition name="cost"> -->
        <div
          :style="{ maxHeight: showCost ? '120px' : '0px' }"
          :class="{ 'drop-border': showCost }"
          class="drop-box drop-transition"
        >
          <div class="menu-box">
            <div @click="goFees">费用概览</div>
            <div @click="goBill">账单明细</div>
            <div @click="goCoupon">优惠券</div>
          </div>
        </div>
        <!-- </transition> -->
      </div>
      <div v-if="userInfo.type === 'master'" class="menu-item" @click="goQuota">
        配额
      </div>
      <div class="menu-item" @click="openDoc">文档</div>

      <div class="message-box">
        <CloudInfo
          :un-read-count="unReadCount"
          @read-message="getUnReadMessageCount"
        />
      </div>

      <div class="info" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <div class="cursor-pointer display-flex user-info-box">
          <div>
            <div class="user-name">{{ userInfo.user_name }}</div>
            <!-- <div class="user-type">{{ userType }}</div> -->
          </div>
          <div class="avatar-box">
            <CloudAvatar
              :show-nickname="false"
              :show-name="false"
              :name="userInfo.user_name || ''"
              :avatar="userInfo.avatar || ''"
              :base-size="16"
            />
          </div>
        </div>

        <div
          class="info-box drop-transition"
          :class="{ 'drop-border': isShow }"
          :style="{ maxHeight: isShow ? '204px' : '0px' }"
        >
          <div class="top-box">
            <div class="display-flex align-items">
              <div class="top-box-user-name omit">
                {{ userInfo.user_name }}
              </div>
              <div class="user-type-wrap">
                <UserType :type="userInfo.type" class="m-l-auto"></UserType>
              </div>
            </div>
            <div class="top-box-user-id">账号ID：{{ userInfo.user_id }}</div>
          </div>
          <div class="bottom-box">
            <div @click="goPersonal">个人中心</div>
            <div v-if="userInfo.type != 'admin'" @click="myToken">
              我的Token
            </div>
            <div @click="loginOut">退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CloudInfo from "@/components/CloudInfo";
import UserType from "@/components/UserType";
import CloudAvatar from "@/components/CloudAvatar";
import Breadcrumb from "./Breadcrumb.vue";
import { appCounterStore } from "@/store/app";
import { permissionStore } from "@/store/permission";
import { useCounterStore } from "@/store/user";
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { resetRouter } from "@/router";
import { notReadMessage } from "@/service/message";

const router = useRouter();
const route = useRoute();
const useStore = useCounterStore();
const appStore = appCounterStore();
const permission = permissionStore();
const userInfo = useStore.getUserInfo;
// console.log(userInfo.avatar);
const isShow = ref(false);
const showCost = ref(false);
const logo = computed(() => {
  return appStore.sidebar
    ? require("@/assets/img/logo2.png")
    : require("@/assets/img/logo.png");
});
const loginOut = async () => {
  permission.resetRouter();
  appStore.setLoginState("login");

  await useStore.logout();
  await useStore.resetToken();
  resetRouter();
  router.push({
    path: `/login`
  });
};
const openDoc = () => {
  window.open(process.env.VUE_APP_DOC_URL);
};
const ebChatOpen = () => {
  const info = process.env;
  const url = info.VUE_APP_EBCHAT_URL;
  window.open(url);
};
const goPersonal = () => {
  router.push({
    path: `/personal/index`
  });
};
const goQuota = () => {
  router.push({
    path: `/quota/index`
  });
};
const goFees = () => {
  router.push({
    path: `/finance/overview`
  });
};
const goBill = () => {
  router.push({
    path: `/finance/expense`
  });
};
const goCoupon = () => {
  router.push({
    path: `/finance/coupon`
  });
};

const myToken = () => {
  router.push({
    path: `/personal/token`
  });
};
const goPortal = () => {
  window.open(process.env.VUE_APP_PORTAL_URL);
};
const goHome = () => {
  if (route.name === "dashboardIndex") {
    location.reload();
  } else {
    router.push({
      path: `/dashboard`
    });
  }
};
const mouseenter = () => {
  isShow.value = true;
};
const mouseleave = () => {
  isShow.value = false;
};
const userType = computed(() =>
  userInfo.type === "master"
    ? "主账号"
    : userInfo.type === "sub"
      ? "子账号"
      : "管理员"
);
const unReadCount = ref(0);
const getUnReadMessageCount = async () => {
  const res = await notReadMessage();
  appStore.setMsgTotal(res.data.total);
  // res.data.total = 99;
  unReadCount.value = res.data.total >= 99 ? "99+" : res.data.total;
};
const timer = setInterval(() => {
  if (localStorage.getItem("isRefresh")) {
    getUnReadMessageCount();
  }
}, 1000 * 10);

onMounted(() => {
  getUnReadMessageCount();
});
onUnmounted(() => {
  clearTimeout(timer);
});
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  align-items: center;
  height: $navbar-height;
  background: transparent;
}
.login-img {
  display: flex;
  cursor: pointer;
  padding-left: 24px;
  img {
    height: 26px;
  }
}
.info {
  display: flex;
  align-items: center;
  height: $navbar-height;
  padding: 0 calc($padding-right - 4px) 0 0;
  font-family: inherit;
  text-align: right;

  .user-name {
    height: 22px;
    color: $neutral-neutral-90;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .user-type {
    height: 17px;
    color: $neutral-neutral-70;
    font-size: 12px;
    line-height: 17px;
    position: relative;
    top: -2px;
  }
  .avatar-box {
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-left: 8px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }
  }
  &:hover .avatar-box {
    background-color: $primary-primaryblue-30;
  }
}
.user-info-box {
  display: flex;
  align-items: center;
  height: 56px;
}
.message-box {
  margin-right: 22px;
  border-radius: 8px;
}
.home-icon-box {
  padding: 8px;
  cursor: pointer;
  margin-right: 22px;
  border-radius: 8px;
}

.home-icon-box:hover {
  background-color: $primary-primaryblue-20;
}
.home-box .home-icon {
  font-size: 24px;
}
.drop-border {
  border: solid 1px $text-quaternary-5;
  box-shadow: 0 19px 21px 0 rgba(0, 0, 0, 0.08);
}
.drop-transition {
  transition: max-height 0.2s;
  overflow: hidden;
}
.info-box {
  position: absolute;
  top: $navbar-height;
  right: calc($padding-right);
  width: 240px;
  text-align: left;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  .top-box {
    padding: 20px 22px;
    height: 84px;
    background-color: $primary-primaryblue-5;

    .top-box-user-name {
      height: 22px;
      margin-bottom: 4px;
      color: $neutral-neutral-90;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      flex: 1;
    }
    .top-box-user-id {
      height: 18px;
      color: $neutral-neutral-70;
      font-size: 13px;
      line-height: 18px;
      margin-top: 4px;
    }
    .tag {
      height: 24px;
      margin-left: auto;
      padding: 0 12px;
      color: $dark-5;
      font-size: 12px;
      line-height: 24px;
      border: solid 1px $dark-5;
      border-radius: 2px;
    }
  }
  .bottom-box {
    padding: 4px;
    > div {
      line-height: 16px;
      padding: 9px 16px;
      border-radius: 12px;
      color: $neutral-neutral-80;
      cursor: pointer;
    }
    > div:not(:last-child) {
      margin-bottom: 4px;
    }
    > div:hover {
      background-color: $primary-primaryblue-15;
    }
  }
  .loginOut {
    width: 100%;
  }
}
.drop-container:hover {
  .menu-item {
    color: $brand-bob-blue-1;
    font-weight: 500;
  }
}
.drop-container {
  position: relative;
}
.drop-box {
  position: absolute;
  top: $navbar-height;
  left: -21px;
  width: 240px;
  text-align: left;
  background-color: #fff;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  .menu-box {
    padding: 4px;
    > div {
      line-height: 16px;
      padding: 9px 16px;
      border-radius: 12px;
      color: $neutral-neutral-80;
      cursor: pointer;
    }
    > div:not(:last-child) {
      margin-bottom: 4px;
    }
    > div:hover {
      background-color: $primary-primaryblue-15;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  height: 204px;
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0;
}
.cost-enter-active,
.cost-leave-active {
  height: 120px;
  transition: all 0.3s ease;
}
.cost-enter, .cost-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0;
}
.omit {
  max-width: 152px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-type-wrap {
  position: relative;
  top: -1px;
}
.text-animation {
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(-45deg, #8c66e6, #8a93ed, #e683ce, #4dd59c);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 400% 400%;
  animation: gradient 2.5s ease infinite alternate;
}
.experience {
  .star-icon {
    font-size: 7px;
    animation: 2s van-cursor-flicker infinite;
  }
  .start-star-icon {
    position: relative;
    bottom: -7px;
  }
  .end-star-icon {
    position: relative;
    top: -11px;
    right: 0px;
  }
}
.h-100 {
  height: 56px;
  display: flex;
  align-items: center;
}
.menu-item,
.experience {
  font-size: 14px;
  cursor: pointer;
  margin-right: 24px;
  color-scheme: unset;
}
.menu-item {
  color: $neutral-neutral-90;
  font-weight: 400;
}
.menu-item:hover {
  color: $brand-bob-blue-1;
  font-weight: 500;
}
@keyframes van-cursor-flicker {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes gradient {
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 100% 50%;
  }
}
</style>
