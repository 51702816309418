import { createApp, nextTick } from "vue";
import Loading from "./index.vue";
const Mask = Loading;
const toggleLoading = (el, binding) => {
    if (binding.value) {
        nextTick(() => {
            // 控制loading组件显示
            el.instance.visible = true;
            el.instance.text = el.getAttribute("element-loading-text") || "";
            // 判断是否需要遮罩层
            el.instance.modal =
                el.getAttribute("element-loading-modal") !== ("false" || false);
            // 插入到目标元素
            if (el.appendToBody) {
                if (!document.body.contains(el.mask)) {
                    document.body.appendChild(el.mask);
                }
            }
            else {
                el.appendChild(el.mask);
            }
        });
    }
    else {
        el.instance.visible = false;
        if (el.appendToBody && el.mask.parentNode === document.body) {
            document.body.removeChild(el.mask);
        }
        else if (el.mask.parentNode === el) {
            el.removeChild(el.mask);
        }
    }
};
export default {
    mounted(el, binding) {
        // 创建一个挂载点
        const app = createApp(Mask);
        const mask = app.mount(document.createElement("div"));
        // 把实例和元素保存到 el 上
        el.instance = mask;
        el.mask = mask.$el;
        // 判断 append-to-body 属性
        el.appendToBody = el.getAttribute("append-to-body") === ("true" || true);
        // 确保目标元素是相对定位或绝对定位（如果不附加到body）
        if (!el.appendToBody && getComputedStyle(el).position === "static") {
            el.style.position = "relative";
        }
        // 根据 binding 的值显示或隐藏 loading
        if (binding.value) {
            toggleLoading(el, binding);
        }
    },
    updated(el, binding) {
        if (binding.oldValue !== binding.value) {
            toggleLoading(el, binding);
        }
    },
    unmounted(el) {
        if (el.app) {
            el.app.unmount(); // 销毁 Vue 3 应用实例
        }
        if (el.appendToBody && el.mask.parentNode === document.body) {
            document.body.removeChild(el.mask);
        }
    }
};
