let observer = null;
const resizePermit = {
    mounted(el, binding) {
        const callback = binding.value;
        observer = new ResizeObserver((entries) => {
            callback(entries[0].contentRect || entries[0][0].contentRect || {});
        });
        observer.observe(el);
        // 在组件卸载时取消观察器
    },
    unmounted() {
        observer?.disconnect();
    }
};
export default resizePermit;
