import axios from "axios";
import { ElMessageBox } from "element-plus";
import { useCounterStore } from "@/store/user";
import { getToken } from "./auth";
import { getMsg } from "@/utils";
const notElMessage = [
    "/user/resource/quota/operators",
    "/user/resource/quota/apply"
];
const msgShow = (value) => {
    return notElMessage.includes(value);
};
const service = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}/v1`
});
service.interceptors.request.use((config) => {
    const userStore = useCounterStore();
    if (userStore.getStoreToken) {
        config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});
let isMessageBoxShowing = false;
let isRefreshToken = false;
const retryOldRequest = {
    //维护失败请求的response
    requestQuery: [],
    //添加订阅者
    listen(response) {
        return new Promise((resolve) => {
            this.requestQuery.push(() => {
                const config = response.config || {};
                config.headers["Token"] = getToken();
                resolve(service(config));
            });
        });
    },
    //发布消息
    trigger() {
        console.log(this.requestQuery);
        // console.log(this.requestQuery);
        this.requestQuery.forEach((fn) => {
            fn();
        });
        this.requestQuery = [];
        isRefreshToken = false;
    }
};
service.interceptors.response.use(async (response) => {
    const userStore = useCounterStore();
    const res = response.data;
    if (res.code !== 0) {
        if (res.code === 2004) {
            if (isMessageBoxShowing) {
                // 如果已经有弹框在显示，直接返回
                return Promise.reject("Token expired");
            }
            const isRefresh = response.config.url?.includes("/user/refreshToken");
            if (isRefresh) {
                isMessageBoxShowing = true;
                localStorage.setItem("isRefresh", "true");
                ElMessageBox.confirm("登录超时，您可以取消以留在此页面，也可以重新登录?", "登录", {
                    confirmButtonText: "登录",
                    cancelButtonText: "取消",
                    distinguishCancelAndClose: true,
                    customClass: "my-message-box",
                    closeOnClickModal: false,
                    callback: (action) => {
                        isMessageBoxShowing = false;
                        if (action === "confirm") {
                            userStore.resetToken().then(() => {
                                location.reload();
                            });
                        }
                        Promise.resolve(); // 确保 resolve Promise，以便继续下一步
                    }
                }).then(() => {
                    userStore.resetToken().then(() => {
                        location.reload();
                    });
                });
            }
            else {
                localStorage.setItem("isRefresh", "false");
                if (!isRefreshToken) {
                    isRefreshToken = true;
                    // console.log(123);
                    await userStore.refreshToken();
                    const config = response.config;
                    config.headers["Token"] = getToken();
                    retryOldRequest.trigger();
                }
                return retryOldRequest.listen(response);
                // return service(config);
            }
        }
        else {
            //直接返回内容
            if (!res.code && res.code !== 0) {
                return response;
            }
            else {
                if (!msgShow(response.config.url)) {
                    getMsg(res.message || "has Error", "error");
                }
                return Promise.reject(res);
            }
        }
    }
    else {
        return res;
    }
}, (error) => {
    console.log("err" + error);
    return Promise.reject(error.message || "error");
});
export default service;
