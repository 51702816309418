<template>
  <div class="home_container">
    <nav class="nav">
      <img class="logo" src="../assets/img/logo.png" alt="" />
      <div class="menu_list">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">产品</el-menu-item>
          <el-menu-item index="2">解决方案</el-menu-item>
          <el-menu-item index="3">合作伙伴</el-menu-item>
          <el-menu-item index="4">关于我们</el-menu-item>
        </el-menu>
      </div>
      <el-button class="login_btn" type="primary" @click="gotoLogin"
        >登录</el-button
      >
    </nav>
    <main class="main">
      <div class="carousel_container">
        <el-carousel trigger="click" height="604px">
          <el-carousel-item v-for="item in carouselImgList" :key="item">
            <div class="carousel_text">
              <div class="title">北京AI创新赋能中心</div>
              <div class="tip">汇聚全球顶尖科技，助力中国产业数字化转型。</div>
              <el-button class="look_btn" type="primary">立即查看</el-button>
            </div>
            <img style="width: 100%; height: 604px" :src="item.src" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="ai_start_container">
        <div class="title">AI之旅，从这里开始</div>
        <div class="ai_card_container">
          <div v-for="item in aiImgList" :key="item" class="ai_card_item">
            <img :src="item.src" alt="" />
            <div class="text_container">
              <div class="title">{{ item.title }}</div>
              <div class="introduction">{{ item.introduction }}</div>
              <div class="divider"></div>
              <div class="tip">{{ item.tip }}</div>
              <el-button class="more_btn" type="text"
                >查看更多<el-icon> <Right /> </el-icon
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="service_container">
        <div class="title">一站式助力企业业务发展的云市场</div>
        <div class="service_item">
          <div class="service_intruction_box">
            <div class="text">
              英博数科以”汇聚全球顶尖科技，助力中国产业数字化转型"为使命
              定位于面向市场为专业AI算力需求和大模型训练提供算力服务、
              架构及落地场景解决方案的服务运营商。
            </div>
            <div class="service_intruction_item_box">
              <div
                v-for="item in serviceIconList"
                :key="item.text"
                class="service_intruction_item"
              >
                <img :src="item.src" alt="" />
                <span class="service_intruction_item_text">{{
                  item.text
                }}</span>
              </div>
            </div>
          </div>
          <div class="feature_box">
            <div
              v-for="item in featureImgList"
              :key="item"
              class="feature_item"
            >
              <img :src="item.src" alt="" />
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage_container">
        <div class="section-statistics">
          <div class="flexit-container">
            <div
              v-for="item in advantageData"
              :key="item"
              class="statistic-column"
            >
              <div class="statistic-value">{{ item.data }}</div>
              <div class="eyebrow eyebrow-white">{{ item.text }}<br />‍</div>
            </div>
          </div>
        </div>
      </div>
      <div class="partner_container">
        <div class="partner_container_text">合作伙伴</div>
        <div class="email">
          欢迎各位开发者、企业和机构与我们一起开创人工智能新时代
        </div>
        <div class="email">
          商务合作邮箱<el-button text type="primary">info@ebtech.com</el-button>
        </div>
        <div class="partners-row">
          <div class="col">
            <img src="@/assets/img/partner.jpg" alt="" /><img
              src="@/assets/img/partner.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="technology_container">
        <div class="technology_title">适用于任何用例的计算资源</div>
        <div class="technology_card_box">
          <div
            v-for="item in technologyData"
            :key="item"
            class="technology_card"
            :style="item.style"
          >
            <div class="technology_card_inner" :style="item.style">
              <div class="top_bar">
                <img src="@/assets/img/brain.jpg" alt="" />
                <span class="technology_card_title">{{ item.title }}</span>
              </div>
              <div class="technology_card_desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="concat_container">
        <div class="concat_title">一键开启云上增长新空间</div>
        <div class="phone">
          <el-input
            v-model="phone"
            style="width: 244px"
            placeholder="请输入您的手机号"
            border="none"
          >
          </el-input>
          <el-button class="register_btn" type="primary">注册登录</el-button>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="company_profile_container">
        <div class="company_item">
          <div class="left_bar">
            <img class="logo" src="../assets/img/logo.png" alt="" />
            <div class="vision">汇聚全球顶尖科技，助力中国产业数字化转型。</div>
            <div class="adress">北京市财富金融中心 53层</div>
            <div class="telephone">+86-18513400229</div>
          </div>
          <div class="right_bar">
            <div class="right_col">
              <div class="right_col_title">网址导航</div>
              <div class="right_col_btn">
                <div class="right_col_btn_text">产品</div>
                <div class="right_col_btn_text">解决方案</div>
                <div class="right_col_btn_text">合作伙伴</div>
              </div>
            </div>
            <div class="right_col">
              <div class="right_col_title">联系我们</div>
              <div class="right_col_btn">
                <div class="right_col_btn_text">商务合作</div>
                <div class="right_col_btn_text">职业机会</div>
                <div class="right_col_btn_text">微信</div>
              </div>
            </div>
            <div class="right_col">
              <div class="right_col_title">关注我们</div>
              <div class="right_col_btn">
                <img src="@/assets/img/publicNumber.jpg" alt="" />
                <div class="right_col_btn_text public_text">公众号</div>
              </div>
            </div>
          </div>
        </div>
        <div class="copy_right">Copyright@2023 京ICP备2023002581号</div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "AboutPortal",
  metaInfo: {
    title: "搏博云",
    meta: [
      {
        name: "keyWords",
        content:
          "搏博云,云服务器,云计算,云资源,AI,云智能,云数据库,CPU,GPU,行业解决方案,"
      },
      {
        name: "description",
        content:
          "提供免费试用、云服务器、云数据库、云安全、云企业应用等云计算服务，以及大数据、人工智能服务、精准定制基于场景的行业解决方案。免费备案，7x24小时售后支持，助企业无忧上云。"
      }
    ],
    link: [
      {
        rel: "英博数科",
        href: "https://cloud.test.ebtech.com/"
      }
    ]
  }
};
</script>
<script setup>
import { Right } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();
const gotoLogin = () => {
  router.push("login");
};
const activeIndex = 1;
const phone = ref();
const handleSelect = () => {};
const carouselImgList = [
  {
    src: require("@/assets/img/carousel1.jpg")
  },
  {
    src: require("@/assets/img/carousel2.jpeg")
  },
  {
    src: require("@/assets/img/carousel3.jpeg")
  },
  {
    src: require("@/assets/img/carousel4.jpeg")
  },
  {
    src: require("@/assets/img/carousel5.jpeg")
  }
];
const aiImgList = [
  {
    src: require("@/assets/img/ai1.jpg"),
    title: "智算中心   超算算力出租",
    introduction:
      "由英伟达DXG系列国内顶配超算服务器和官方推荐网络传输DDN存储解决方案组成的超算中心",
    tip: "国内首个提供公共算力出租的Superpod DGX完整标准算力单元"
  },
  {
    src: require("@/assets/img/ai2.jpg"),
    title: "云资源采购平台",
    introduction: "为客户提供",
    tip: "规划，采购，建设，托管运营的高端AIDC一站式服务。"
  },
  {
    src: require("@/assets/img/ai3.jpg"),
    title: "培训中心",
    introduction: "积极响应《数字中国建设整体布局规范》",
    tip: "培养创新型，应用型，复合型AI人才为产业数字化转型做好人才储备"
  },
  {
    src: require("@/assets/img/ai4.jpg"),
    title: "产业实验室",
    introduction: "以AIDC算力爆发地为抓手",
    tip: "培养创新型，应用型，复合型AI人才为产业数字化转型做好人才储备"
  }
];

const featureImgList = [
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "高性能CPU"
  },
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "NVIDIA GPU"
  },
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "NVLink"
  },
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "Infini Band"
  },
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "高速以太网"
  },
  {
    src: require("@/assets/img/cpu.jpg"),
    text: "大功率电源"
  }
];
const serviceIconList = [
  {
    src: require("@/assets/img/cloud_icon.jpg"),
    text: "云管服务"
  },
  {
    src: require("@/assets/img/cloud_icon.jpg"),
    text: "资源调度"
  },
  {
    src: require("@/assets/img/cloud_icon.jpg"),
    text: "存储服务"
  },
  {
    src: require("@/assets/img/cloud_icon.jpg"),
    text: "数据服务"
  },
  {
    src: require("@/assets/img/cloud_icon.jpg"),
    text: "向量数据库"
  }
];
const advantageData = [
  {
    data: "35x",
    text: "更快"
  },
  {
    data: "80%",
    text: "更便宜"
  },
  {
    data: "50%",
    text: "减少延迟"
  },
  {
    data: "5",
    text: "北美4级数据中心"
  }
];
const technologyData = [
  {
    title: "机器学习与人工智能",
    desc: "访问与基础架构上的模型复杂性相匹配的计算资源，使您能够大规模运行推理",
    style: "background-image: linear-gradient(to bottom, #c8dffa, #fff);"
  },
  {
    title: "视觉特效和渲染",
    desc: "访问与基础架构上的模型复杂性相匹配的计算资源，使您能够大规模运行推理",
    style: "background-image: linear-gradient(to bottom, #dbf9f2, #fff);"
  },
  {
    title: "像素流",
    desc: "访问与基础架构上的模型复杂性相匹配的计算资源，使您能够大规模运行推理",
    style: "background-image: linear-gradient(to bottom, #e9e6fe, #fff);"
  }
];
</script>

<style lang="scss" scoped>
.home_container {
  display: flex;
  flex-direction: column;
  // background-color: $others-white;
  .nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    padding: 18px 40px;
    background-color: #fff;
    opacity: 0.85;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    .logo {
      width: 80px;
    }
    .menu_list {
      display: inline-block;
      width: 430px;
      margin-left: 80px;
      :deep() .el-menu--horizontal {
        border-bottom: none;
      }
    }
    .login_btn {
      position: absolute;
      right: 40px;
      width: 68px;
      height: 32px;
    }
  }
  .main {
    flex: 1;
    .carousel_container {
      position: relative;
      .carousel_text {
        position: absolute;
        top: 216px;
        left: 240px;
        .title {
          width: 537px;
          height: 84px;
          margin: 0 0 8px;
          color: $others-black;
          font-weight: 600;
          font-size: 60px;
          font-family: PingFangSC;
        }
        .tip {
          width: 420px;
          height: 28px;
          margin: 8px 117px 40px 0;
          color: $others-black;
          font-size: 20px;
        }
        .look_btn {
          width: 160px;
          height: 48px;
        }
      }
    }
    .ai_start_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
      background-color: $others-white;
      .title {
        width: 322px;
        height: 50px;
        margin: 100px 31px 48px 95px;
        color: $others-black;
        font-weight: 600;
        font-size: 36px;
      }
      .ai_card_container {
        display: flex;
        gap: 24px;
        justify-content: center;
        width: 100%;
        .ai_card_item {
          display: flex;
          flex-direction: column;
          width: 25%;
          max-width: 342px;
          // height: 450px;
          // margin: 0 12px;
          // transition: transform 0.15s;
          &:hover {
            // transform: translateY(-20px);
          }
          img {
            width: 342px;
          }
          .text_container {
            width: 342px;
            height: 250px;
            padding: 24px;
            background-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.82) 0%,
              #fff 18%
            );
            border-radius: 4px;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            .title {
              width: 264px;
              height: 33px;
              margin: 0 30px 8px 0;
              color: $others-black;
              font-weight: 600;
              font-size: 24px;
            }
            .introduction {
              width: 294px;
              height: 40px;
              margin: 8px 0 20px;
              color: $neutral-neutral-80;
              font-size: 12px;
              line-height: 1.67;
            }
            .divider {
              width: 44px;
              height: 1px;
              margin: 20px 0;
              background-color: $neutral-neutral-60;
            }
            .more_btn {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .service_container {
      width: 100%;
      height: 488px;
      background-color: #fff;
      .title {
        width: 540px;
        height: 50px;
        margin: 0 auto;
        margin-top: 60px;
        color: $others-black;
        font-weight: 500;
        font-size: 36px;
        font-family: PingFangSC;
      }
      .service_item {
        display: flex;
        justify-content: space-around;
        .service_intruction_box {
          width: 50%;
          margin: 48px 0px 37px 60px;
          .text {
            width: 547px;
            height: 78px;
            margin: 48px 202px 37px 0px;
            color: $neutral-neutral-90;
            font-size: 16px;
            font-family: PingFangSC;
            line-height: 1.63;
          }
          .service_intruction_item_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .service_intruction_item {
              display: flex;
              align-items: center;
              width: 50%;

              margin: 12px 0px;
              .service_intruction_item_text {
                margin: 1px 0px 0px 10px;
                color: $neutral-neutral-90;
                font-size: 16px;
              }
            }
          }
        }
        .feature_box {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          max-width: 800px;
          .feature_item {
            display: flex;
            width: 30%;
            height: 114px;
            margin: 48px 0 1px 16px;
            padding: 35px 8px 35px 24px;
            background-image: linear-gradient(to bottom, #f3f6fe, #fff 150%);
            border: solid 1px #fff;
            border-radius: 4px;
            img {
              width: 44px;
            }
            .text {
              display: inline-block;
              height: 22px;
              margin: 11px 0 11px 16px;
              color: $neutral-neutral-90;
              font-size: 16px;
            }
          }
        }
      }
    }
    .advantage_container {
      width: 100%;
      height: 200px;
      // margin: 0 120px;
      background-image: linear-gradient(359deg, #26306d 116%, #1c1e37 -65%);
      .section-statistics {
        position: relative;
        width: 95%;
        max-width: 1160px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 2.15em;
        padding-bottom: 2.5em;
        .flexit-container {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          align-items: center;
          justify-content: space-between;
          .statistic-column {
            width: 25%;
            text-align: center;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            .statistic-value {
              color: #fff;
              font-size: 70px;
            }
            .eyebrow.eyebrow-white {
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }
    .partner_container {
      // width: 1200px;
      // margin: 0 auto;
      background-color: $others-white;
      .partner_container_text {
        margin-bottom: 12px;
        padding-top: 120px;
        color: $others-black;
        font-weight: 500;
        font-size: 36px;
        text-align: center;
      }
      .email {
        // width: 378px;
        // height: 48px;
        // margin: 12px 3px 40px 67px;
        color: $neutral-neutral-90;
        font-size: 14px;
        line-height: 1.71;
        text-align: center;
      }
      .partners-row {
        position: relative;
        width: 1200px;
        height: fit-content;
        min-height: 75px;
        margin: 0 auto;
        margin-top: 30px;
        overflow: hidden;
        .col {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          width: 200%;
          width: 2368px;
          height: 75px;
          animation-name: scrollLeft;
          animation-duration: 68s;
          animation-play-state: running;
          animation-timing-function: linear;
          animation-delay: 1s;
          animation-iteration-count: infinite;
          animation-direction: normal;
          animation-fill-mode: none;
          img {
            float: left;
            width: 1184px;
            height: 75px;
            padding-left: 16px;
          }
        }
      }
      @keyframes scrollLeft {
        0% {
          left: 0;
        }

        to {
          left: -1184px;
        }
      }
    }
    .technology_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      background-color: $others-white;
      .technology_title {
        height: 50px;
        margin-bottom: 48px;
        padding: 62px 40px 48px;
        color: $others-black;
        font-weight: 500;
        font-size: 36px;
        text-align: center;
      }
      .technology_card_box {
        display: flex;
        justify-content: center;
        .technology_card {
          width: 33.3%;
          // height: 240px;
          margin: 0px 24px 120px 0px;
          // padding: 32px 32px 48px;
          border-radius: 4px;
          // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);

          &:hover {
            transform: translateY(-20px);
          }
          .top_bar {
            display: flex;
            align-items: center;
            .technology_card_title {
              height: 33px;
              margin-left: 16px;
              // margin: 1px 129px 78px 16px;
              color: $neutral-neutral-90;
              font-weight: 600;
              font-size: 24px;
            }
          }
          .technology_card_desc {
            width: 400px;
            height: 48px;
            margin: 77px 0 0;
            color: $neutral-neutral-90;
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }
    }
    .concat_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 220px;
      margin: 0 auto;
      background-image: linear-gradient(359deg, #26306d 116%, #1c1e37 -65%);
      .concat_title {
        // width: 352px;
        height: 45px;
        margin: 0px 4px 23px;
        padding-top: 48px;
        color: #fff;
        font-weight: 500;
        font-size: 32px;
        text-align: center;
      }
      .phone {
        width: 360px;
        height: 44px;
        margin: 46px 0 0;
        padding: 4px 4px 4px 16px;
        background-color: #fff;
        border-radius: 4px;
        :deep().el-input__wrapper {
          box-shadow: none;
          cursor: default;
        }
        :deep().el-input__wrapper.is-focus {
          box-shadow: none;
          cursor: default;
        }
        :deep().el-input__wrapper:hover {
          box-shadow: none;
          cursor: default;
        }
      }
      .register_btn {
        width: 96px;
        height: 36px;
        // margin: 0 0 0 132px;
        padding: 6px 20px;
        background-color: $primary;
        border-radius: 4px;
      }
    }
  }
  .footer {
    width: 100%;
    height: 335px;
    .company_profile_container {
      margin: 0 auto;
      .company_item {
        display: flex;
        justify-content: space-around;
        .left_bar {
          margin-top: 50px;
          .logo {
            width: 80px;
          }
          .vision {
            width: 294px;
            height: 20px;
            margin: 12px 0 32px;
            color: $neutral-neutral-90;
            font-size: 14px;
          }
          .address {
            width: 162px;
            height: 20px;
            margin: 32px 132px 8px 0;
            color: $neutral-neutral-90;
            font-size: 14px;
          }
          .telephone {
            width: 121px;
            height: 20px;
            margin: 8px 173px 0 0;
            color: $neutral-neutral-90;
            font-size: 14px;
          }
        }
        .right_bar {
          display: flex;
          .right_col {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
            margin-right: 160px;
            .right_col_title {
              width: 64px;
              height: 22px;
              margin-bottom: 12px;
              color: $neutral-neutral-90;
              font-weight: 500;
              font-size: 16px;
            }
            .right_col_btn {
              display: flex;
              flex-direction: column;
              .right_col_btn_text {
                width: 64px;
                height: 40px;
                text-align: left;
                cursor: pointer;
                &:hover {
                  color: $primary;
                }
              }
              img {
                width: 88px;
              }
              .public_text {
                padding-left: 20px;
              }
            }
          }
        }
      }
      .copy_right {
        height: 17px;
        margin: 35px 150px 0 10.5px;
        color: $neutral-neutral-90;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  // .jump_up {
  //   &:hover {
  //     .jump_up_inner {
  //       transform: translateY(-20px);
  //     }
  //   }
  //   .jump_up_inner {
  //     transition: transform 0.15s;
  //   }
  // }
}
</style>
