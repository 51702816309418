import router from "./index";
import { permissionStore } from "@/store/permission";
import { appCounterStore } from "@/store/app";
import { getToken, getRoles } from "@/utils/auth";
import { getQueryParam } from "@/utils";
const whiteList = ["login", "modifyPassword", "about"];
router.beforeEach(async (to, from, next) => {
    const permission_store = permissionStore();
    const appStore = appCounterStore();
    const hasToken = getToken();
    if (hasToken) {
        const roles = getRoles();
        if (permission_store.getAddRoutes.length === 0) {
            const accessRoutes = await permission_store.generateRoutes(roles);
            for (const i of accessRoutes) {
                router.addRoute(i);
            }
            next({ ...to, replace: true });
        }
        else {
            const redirect_url = getQueryParam("redirect_url");
            const loginState = appStore.getLoginState;
            if (loginState === "already") {
                if (getQueryParam("redirect_url")) {
                    appStore.setLoginState("login");
                }
                if (to.path === "/login" && !redirect_url) {
                    next("/");
                }
                else {
                    next();
                }
            }
            else {
                if (whiteList.indexOf(to.name) !== -1) {
                    next();
                }
                else {
                    next(`/login`);
                }
            }
        }
    }
    else {
        if (whiteList.indexOf(to.name) !== -1) {
            next();
        }
        else {
            next(`/login?redirect=${to.fullPath}`);
        }
    }
});
