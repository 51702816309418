<template>
  <el-dropdown class="menu" popper-class="my-dropdown-popper-arrow my-popper">
    <slot></slot>
    <template #dropdown>
      <div class="item-box">
        <template v-for="item in data" :key="item.name">
          <div
            class="item"
            :class="{ disabled: item.disabled }"
            @click="itemClick(item.disabled, item.callback)"
          >
            <svg-icon v-if="item.icon" :icon-class="item.icon"></svg-icon>
            <span class="item-text">{{ item.name }}</span>
          </div>
        </template>
      </div>
    </template>
  </el-dropdown>
</template>
<script setup>
const props = defineProps({
  data: {
    type: Array,
    default: () => []
  }
});
const itemClick = (disabled, callback) => {
  if (disabled) {
    return;
  }
  callback?.();
};
</script>
<style lang="scss" scoped>
.menu {
  position: relative;
  display: inline-block;
  margin-left: 6px;
  padding: 1px 0;
  vertical-align: baseline;
}

.item-box {
  min-width: 92px;
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
}
.item {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px 12px;
  color: $neutral-neutral-80;
  border-radius: 8px;
  cursor: pointer;
  &.disabled {
    color: $neutral-neutral-50;
  }
}
.item:hover {
  background-color: $primary-primaryblue-20;
}
.item-text {
  margin-left: 4px;
  font-size: 13px;
}
</style>
<style></style>
