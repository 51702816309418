<template>
  <a
    v-if="open"
    :href="hostname+'/#'+to"
    target="_blank"
  >
    <slot />
  </a>
  <router-link
    v-else
    :to="to"
  >
    <slot />
  </router-link>
</template>

<script setup>
import { ref } from "vue";

const hostname = ref(window.location.origin);

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
});
</script>
