import { ElMessage } from "element-plus";
import { h } from "vue";
let messageInstance = null;
const icon = {
    success: "msg-success",
    error: "msg-fail",
    warning: "msg-warnning",
    info: "msg-info"
};
const createSvgIcon = (name, className) => {
    return h("svg", { class: `${className} svg-icon`, "aria-hidden": true }, [
        h("use", { "xlink:href": `#icon-${name}` })
    ]);
};
const close = () => {
    messageInstance?.close();
};
export function CloudElMessage(config = {}) {
    const { message = "Message can be ", type = "info" } = config;
    messageInstance = ElMessage({
        customClass: "cloud-message",
        // duration: 0,
        icon: createSvgIcon(icon[type], "type-icon"),
        message: h("div", { class: "vertical-center flex-1", onClick: close }, [
            h("div", { class: "content" }, message)
            // createSvgIcon("close", "close-icon")
        ])
    });
}
