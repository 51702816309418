const TokenKey = "Token";
const Roles = "Roles";
const RefreshTokenKey = "RefreshToken";
const UserInfo = "userInfo";
const Instance = "instance";
const CurrentInstance = "currentInstance";
const CurrentConnect = "currentConnect";
const AccountInfo = "accountInfo";
const LoginState = "loginState";
const white = [
    "userInfo",
    "RefreshToken",
    "Roles",
    "loginState",
    "Token",
    "accountInfo"
];
export function getToken() {
    return localStorage.getItem(TokenKey);
}
export function setToken(token) {
    return safeLocalStorageOperation(TokenKey, token);
}
export function removeToken() {
    return localStorage.removeItem(TokenKey);
}
export function getLoginStateValue() {
    return localStorage.getItem(LoginState);
}
export function setLoginStateValue(state) {
    return safeLocalStorageOperation(LoginState, state);
}
export function removeLoginStateValue() {
    return localStorage.removeItem(LoginState);
}
export function getRoles() {
    return JSON.parse(localStorage.getItem(Roles) || "{}");
}
export function setRoles(roles) {
    return safeLocalStorageOperation(Roles, JSON.stringify(roles));
}
export function removeRoles() {
    return localStorage.removeItem(Roles);
}
export function getRefreshToken() {
    return localStorage.getItem(RefreshTokenKey);
}
export function setRefreshToken(token) {
    return safeLocalStorageOperation(RefreshTokenKey, token);
}
export function removeRefreshToken() {
    return localStorage.removeItem(RefreshTokenKey);
}
export function getUserInfo() {
    return JSON.parse(localStorage.getItem(UserInfo) || "{}");
}
export function setUserInfo(info) {
    return safeLocalStorageOperation(UserInfo, JSON.stringify(info));
}
export function removeUserInfo() {
    return localStorage.removeItem(UserInfo);
}
export function getAccountInfo() {
    return JSON.parse(localStorage.getItem(AccountInfo) || "{}");
}
export function setAccountInfo(info) {
    return safeLocalStorageOperation(AccountInfo, JSON.stringify(info));
}
export function removeAccountInfo() {
    return localStorage.removeItem(AccountInfo);
}
export function getInstance() {
    return JSON.parse(sessionStorage.getItem(Instance) || "{}");
}
export function setInstance(instance) {
    return sessionStorage.setItem(Instance, JSON.stringify(instance));
}
export function removeInstance() {
    return sessionStorage.removeItem(Instance);
}
export function getCurrentInstance() {
    return sessionStorage.getItem(CurrentInstance);
}
export function setCurrentInstance(value) {
    return sessionStorage.setItem(CurrentInstance, value);
}
export function removeCurrentInstance() {
    return sessionStorage.removeItem(CurrentInstance);
}
export function getCurrentConnect() {
    return JSON.parse(sessionStorage.getItem(CurrentConnect) || "{}");
}
export function setCurrentConnect(value) {
    return sessionStorage.setItem(CurrentConnect, JSON.stringify(value));
}
export function removeCurrentConnect() {
    return sessionStorage.removeItem(CurrentConnect);
}
function safeLocalStorageOperation(key, value) {
    try {
        return localStorage.setItem(key, value);
    }
    catch (error) {
        console.error(`Failed to ${key} item in localStorage:`, error);
        let deleteStr = "";
        for (const key in localStorage) {
            if (!white.includes(key)) {
                if (!isStringOver20KB(deleteStr)) {
                    deleteStr += localStorage.getItem(key);
                    localStorage.removeItem(key);
                }
            }
        }
        return localStorage.setItem(key, value);
    }
}
function isStringOver20KB(str) {
    const textEncoder = new TextEncoder();
    const byteArray = textEncoder.encode(str);
    const byteLength = byteArray.length;
    // 20KB = 20 * 1024 bytes
    return byteLength > 20 * 1024;
}
