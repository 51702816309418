import { defineStore } from "pinia";
import { getLoginStateValue, setLoginStateValue } from "@/utils/auth";
export const appCounterStore = defineStore("app", {
    state: () => ({
        loginState: getLoginStateValue() || "login",
        sidebar: false,
        msgTotal: 0
    }),
    getters: {
        getLoginState(state) {
            return state.loginState;
        },
        getSidebar(state) {
            return state.sidebar;
        },
        getMsgTotal(state) {
            return state.msgTotal;
        }
    },
    actions: {
        setLoginState(value) {
            this.loginState = value;
            setLoginStateValue(value);
        },
        setSidebar(value) {
            this.sidebar = value;
        },
        setMsgTotal(value) {
            this.msgTotal = value;
        }
    }
});
