import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "@/assets/css/base.scss";
import router from "@/router/index";
import "@/router/permission";
import VueDOMPurifyHTML from "vue-dompurify-html";
const app = createApp(App);
import { setupStore } from "@/store";
setupStore(app);
import { svgIcon } from "@/icons";
svgIcon(app);
import MetaInfo from "vue-meta-info";
app.use(MetaInfo);
import directive from "@/directive/index";
directive(app);
app.use(router);
app.use(ElementPlus, {
    locale: zhCn
});
app.use(VueDOMPurifyHTML);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (fn, delay) => {
    let timer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (...rest) {
        const args = rest;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn(args);
        }, delay);
    };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
app.mount("#app");
