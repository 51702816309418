import { defineStore } from "pinia";
import { asyncRoutes, constantRoutes, errorRoutes } from "@/router/index";
import { getWebControlList } from "@/service/permission";
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        try {
            return roles.some((role) => route.meta.roles.includes(role));
        }
        catch { }
    }
    else {
        return true;
    }
}
export function filterAsyncRoutes(routes, roles) {
    const res = [];
    routes.forEach((route) => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            res.push(tmp);
        }
    });
    return res;
}
export const permissionStore = defineStore("permission", {
    state: () => ({
        routes: [],
        addRoutes: [],
        authList: []
    }),
    getters: {
        getRoutes(state) {
            return state.routes;
        },
        getAddRoutes(state) {
            return state.addRoutes;
        }
    },
    actions: {
        fetchAuthList() {
            return new Promise((resolve) => {
                getWebControlList({}).then((res) => {
                    this.authList = res.data;
                    resolve(res.data);
                });
            });
        },
        resetRouter() {
            this.routes = [];
            this.addRoutes = [];
        },
        generateRoutes(roles) {
            return new Promise((resolve) => {
                const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
                this.addRoutes = accessedRoutes;
                this.routes = constantRoutes.concat(accessedRoutes).concat(errorRoutes);
                resolve(accessedRoutes.concat(errorRoutes));
            });
        }
    }
});
