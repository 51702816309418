import request from "@/utils/request";
export function getRoleList(data) {
    return request({
        url: "/role/list",
        method: "GET",
        params: data
    });
}
export function getRelateRoleList(data) {
    return request({
        url: "/role/relateList",
        method: "GET",
        params: data
    });
}
export function createRole(data) {
    return request({
        url: "/role/create",
        method: "POST",
        data
    });
}
export function updateRole(data) {
    return request({
        url: "/role/update",
        method: "POST",
        data
    });
}
export function deleteRole(data) {
    return request({
        url: "/role/delete",
        method: "POST",
        data
    });
}
export function setRolefromUser(data) {
    return request({
        url: "/role/setUser",
        method: "POST",
        data
    });
}
export function setRolefromGroup(data) {
    return request({
        url: "/role/setUserGroup",
        method: "POST",
        data
    });
}
export function getAuthList(data) {
    return request({
        url: "/auth/list",
        method: "GET",
        params: data
    });
}
export function setAuthRole(data) {
    return request({
        url: "/auth/setRole",
        method: "POST",
        data
    });
}
export function getRelateAuthList(data) {
    return request({
        url: "/auth/relateList",
        method: "GET",
        params: data
    });
}
export function getMenuList(data) {
    return request({
        url: "/menu/list",
        method: "GET",
        params: data
    });
}
export function getcheckMenuList(data) {
    return request({
        url: "/auth/selectList",
        method: "GET",
        params: data
    });
}
export function getRoleDetail(data) {
    return request({
        url: "/role/detail",
        method: "GET",
        params: data
    });
}
export function getWebControlList(data) {
    return request({
        url: "/auth/webControlList",
        method: "GET",
        params: data
    });
}
