import { CloudElMessage } from "@/components/CloudMessage";
import CloudButton from "@/components/CloudButton/index.vue";
import CloudButtonGroup from "@/components/CloudButtonGroup/index.vue";
import DropDownMenu from "@/components/DropDownMenu/index.vue";
import router from "@/router";
const CryptoJS = require("crypto-js");
import dayjs from "dayjs";
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}
export function isEmpty(val) {
    return (val === "" ||
        val === undefined ||
        val === null ||
        val === "-" ||
        Number.isNaN(val));
}
export function exportFile(data, filename = "导出文档", type) {
    const blob = new Blob([data], {
        type: type
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    link.click();
}
export function isImageFile(filename) {
    var imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".tiff",
        ".svg"
    ];
    // 获取文件扩展名
    var fileExtension = filename.toLowerCase().split(".").pop();
    // 检查文件扩展名是否在图片扩展名列表中
    if (imageExtensions.includes("." + fileExtension)) {
        return true;
    }
    else {
        return false;
    }
}
export function formatTimeAgo(timeString) {
    const now = dayjs(); // 获取当前时间
    const targetTime = dayjs(timeString); // 将时间字符串转换成 Day.js 对象
    const diffMinutes = now.diff(targetTime, "minute"); // 计算时间差（分钟）
    if (diffMinutes < 1) {
        return "刚刚";
    }
    else if (diffMinutes < 60) {
        return `${diffMinutes}分钟前`;
    }
    else {
        const diffHours = now.diff(targetTime, "hour"); // 计算时间差（小时）
        if (diffHours < 24) {
            return `${diffHours}小时前`;
        }
        else {
            const diffDays = now.diff(targetTime, "day"); // 计算时间差（天数）
            if (diffDays === 1) {
                return "昨天";
            }
            else if (diffDays < 30) {
                return `${diffDays}天前`;
            }
            else {
                const diffMonths = now.diff(targetTime, "month"); // 计算时间差（月数）
                if (diffMonths < 12) {
                    return `${diffMonths}月前`;
                }
                else {
                    const diffYears = now.diff(targetTime, "year"); // 计算时间差（年数）
                    return `${diffYears}年前`;
                }
            }
        }
    }
}
export function formatBytes(bytes) {
    if (bytes < 1024) {
        return bytes + " B";
    }
    else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    }
    else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    }
    else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
}
export function formatFileSize(bytes) {
    if (bytes < 1024) {
        return bytes + " B";
    }
    else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    }
    else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    }
    else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
}
export function checkEmail(rule, value, callback) {
    const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!pattern.test(value)) {
        callback(new Error("邮箱格式不正确，请重新输入!"));
    }
    callback();
}
export function checkLoginName(rule, value, callback) {
    // const pattern = /^(?!-)(?!.*--)[a-z0-9-]{6,32}$/;
    const pattern = /^[a-z0-9]{6,32}$/;
    if (!pattern.test(value)) {
        // callback(new Error("6-32字符, 支持包含小写字母、数字和-, 不能以-开头"));
        callback(new Error("6-32字符, 仅支持包含小写字母、数字"));
    }
    callback();
}
export function checkName(rule, value, callback) {
    const pattern = /^[a-z][a-z0-9-]{3,18}[a-z0-9]$/;
    if (!pattern.test(value)) {
        callback(new Error("5-20字符，仅支持小写字母开头，内容支持包含小写字母、数字和'-'，不能以'-'结尾"));
    }
    callback();
}
export const checkName2 = (rule, value, callback) => {
    const pattern = /^(?![_-])[a-zA-Z0-9_\u4e00-\u9fa5-]{5,32}$/;
    if (!pattern.test(value)) {
        callback(new Error("5-32个字符，支持中文、大小写英文字母、数字、下划线'_'、短横线'-'，不支持以下划线'_'、短横线'-'开头"));
    }
    callback();
};
export const checkName3 = (rule, value, callback) => {
    const pattern = /^(?=.{5,64}$)[A-Za-z0-9][A-Za-z0-9_-]*(\.[A-Za-z0-9_-]*\d[A-Za-z0-9_-]*)*$/;
    if (!pattern.test(value)) {
        callback(new Error("5-64字符，仅支持大小写字母和数字开头，内容支持包含大小写字母、数字、下划线'_'、短横线-和点'.'，'.'后必须包含数字"));
    }
    callback();
};
export function checkName4(rule, value, callback) {
    const pattern = /^[a-z][a-z0-9.-]{3,18}[a-z0-9]$/;
    if (!pattern.test(value)) {
        callback(new Error("5-20字符，支持小写字母、数字、“-”和“.”；且需以小写字母开头，不能以“-”和“.”结尾"));
    }
    callback();
}
export function checkPhone(rule, value, callback) {
    const pattern = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!pattern.test(value)) {
        callback(new Error("手机号码格式不正确，请重新输入!"));
    }
    callback();
}
//校验身份证号码。
export function chineseIDCardRegex(rule, value, callback) {
    const pattern = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}([0-9Xx])$/;
    if (!pattern.test(value)) {
        callback(new Error("身份证号格式不正确，请重新输入!"));
    }
    callback();
}
/**
 * 仅支持字母、数字、特殊字符（空格除外）。
 * 长度为 8 到 20 个字符。
 * 必须包含至少两种类型的字符：字母、数字、特殊字符。
 */
export function checkPassword(rule, value, callback) {
    const pattern = /^(?=\S{8,20}$)(?:(?=.*[a-zA-Z])(?=.*\d)|(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\\-])|(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\\-]))\S+$/;
    if (!pattern.test(value)) {
        callback(new Error("密码格式设置不符合安全规则!"));
    }
    callback();
}
//仅支持字母、数字、特殊字符（空格除外）。
export function checkPassword1(value) {
    const pattern = /^(?!\s*$)[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/\\-]*$/;
    return pattern.test(value);
}
//长度为 8 到 20 个字符
export function checkPassword2(value) {
    const pattern = /^[\s\S]{8,20}$/;
    return pattern.test(value);
}
//必须包含至少两种类型的字符：字母、数字、特殊字符。
export function checkPassword3(value) {
    const pattern = /^(?:(?=.*[a-zA-Z])(?=.*\d)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9\s])|(?=.*\d)(?=.*[^a-zA-Z0-9\s])).*$/;
    return pattern.test(value);
}
//防抖
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;
    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp;
        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        }
        else {
            timeout = null;
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout)
                    context = args = null;
            }
        }
    };
    return function (...args) {
        /* eslint-disable @typescript-eslint/no-this-alias */
        context = this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout)
            timeout = setTimeout(later, wait);
        if (callNow) {
            console.log("immediate");
            result = func.apply(context, args);
            context = args = [];
        }
        return result;
    };
}
//节流
export function throttle(fn, interval) {
    // 1.记录上一次的开始时间
    let lastTime = 0;
    // 2.事件触发时, 真正执行的函数
    const _throttle = function () {
        // 2.1.获取当前事件触发时的时间
        const nowTime = new Date().getTime();
        // 2.2.使用当前触发的时间和之前的时间间隔以及上一次开始的时间, 计算出还剩余多长事件需要去触发函数
        const remainTime = interval - (nowTime - lastTime);
        if (remainTime <= 0) {
            // 2.3.真正触发函数
            fn();
            // 2.4.保留上次触发的时间
            lastTime = nowTime;
        }
    };
    return _throttle;
}
//消息弹出
export function getMsg(msg, type = "success") {
    CloudElMessage({
        message: msg,
        type: type
    });
}
//时间转换
export function getTime(timestamp) {
    if (!timestamp) {
        return "";
    }
    var date = new Date(timestamp);
    var Y = date.getFullYear() + "-";
    var M = (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}
//密码哈希
export function useBcrypt(password) {
    const hash = CryptoJS.MD5(password).toString();
    return hash;
}
//判断字符串长度
export function getActualWidthOfChars(text, options = { size: 14, family: "PingFang SC" }) {
    const { size = 14, family = "PingFang SC" } = options;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d") || new CanvasRenderingContext2D();
    ctx.font = `${size}px ${family}`;
    const metrics = ctx.measureText(text);
    const actual = Math.abs(metrics.actualBoundingBoxLeft) +
        Math.abs(metrics.actualBoundingBoxRight);
    return Math.max(metrics.width, actual);
}
//价格千分位保留两位小数
export function dataHandle(num) {
    let number = num ? Number.parseFloat(num).toFixed(2) : "0.00";
    const options = {
        style: "currency",
        currency: "CNY"
    };
    let result = Number.parseFloat(number)
        .toLocaleString("zh-CN", options)
        .replace(/¥(\d)/g, "$1");
    return result;
}
// 转换折扣率
export function discountConversion(value) {
    const percentage = value;
    const percentageValue = parseFloat(percentage) / 100; // 将百分比转换为小数
    const discountValue = (percentageValue * 10).toFixed(1); // 转换为折扣值
    return discountValue;
}
export const jumpHandle = (path) => {
    router.push({
        path: path
    });
};
//返回
export const goBack = () => {
    router.go(-1);
};
// 按钮组合
export function buttonGroup(value) {
    const ele = value.filter((item) => {
        const { show = true } = item;
        return show;
    });
    const cutOff = ele.length <= 3 ? ele.length : 2;
    const ele_show = ele.slice(0, cutOff);
    const ele_hide = ele.slice(cutOff);
    const elements = ele_show.map((item) => {
        const { disabled = false, text = true, callback, name, type } = item;
        return (<CloudButton type={type} text={text} disabled={disabled} onClick={callback}>
        {name}
      </CloudButton>);
    });
    return (<CloudButtonGroup>
      {elements}
      {ele_hide.length !== 0 && (<DropDownMenu data={ele_hide}>
          {<CloudButton type="text" class="m-l-6">
              更多
            </CloudButton>}
        </DropDownMenu>)}
    </CloudButtonGroup>);
}
//去除介绍--- ---中间内容
export const cleanedText = (value) => {
    return value.replace(/---[\s\S]*?---/, "");
};
//解析URL参数
export const getQueryParam = (param) => {
    // 构建正则表达式以匹配参数
    const regex = new RegExp("[?&]" + param + "=([^&#]*)");
    const results = regex.exec(window.location.href);
    // 返回参数值或null
    return results ? decodeURIComponent(results[1].replace(/\+/g, " ")) : null;
};
//替换markdown种图片地址
export const getImg = (str, id) => {
    const prefix = `https://console.cloud.test.ebtech-inc.com/v1/git/view/repository/files?ref=main&id=${id}&file_name=`;
    const updatedStr = str.replace(/(!\[.*?\]\()(.*?)(\))/g, (match, p1, p2, p3) => {
        // 检查是否已经有前缀
        if (!/^https?:\/\//.test(p2)) {
            return `${p1}${prefix}${p2}${p3}`;
        }
        return match;
    });
    return updatedStr;
};
//转化天时分秒
export function formatTime(seconds) {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    const parts = [
        { value: days, unit: "天" },
        { value: hours, unit: "时" },
        { value: minutes, unit: "分" },
        { value: secs, unit: "秒" }
    ];
    return parts
        .filter((part) => part.value > 0)
        .map((part) => `${part.value} ${part.unit}`)
        .join(" ");
}
//
export function disabledFilter(data) {
    const value = (data || []).filter((item) => {
        return item.disable !== 1;
    });
    return value;
}
//两数相加
export function preciseAdd(num1, num2) {
    // 将输入转换为浮点数
    const number1 = parseFloat(num1);
    const number2 = parseFloat(num2);
    // 避免浮点数精度问题
    const sum = number1 + number2;
    const roundedSum = Math.round((sum + Number.EPSILON) * 100) / 100;
    // 判断结果是否为整数
    if (Number.isInteger(roundedSum)) {
        return roundedSum;
    }
    else {
        return roundedSum.toFixed(2);
    }
}
export function toFixedTwo(value) {
    const number = parseFloat(value);
    if (Number.isInteger(number)) {
        return number;
    }
    else {
        return number.toFixed(2);
    }
}
export function getLanguageFromFileName(fileName) {
    const extension = fileName.split(".").pop();
    const languageMap = {
        js: "javascript",
        ts: "typescript",
        py: "python",
        java: "java",
        c: "c",
        cpp: "cpp",
        rb: "ruby",
        sh: "bash",
        bash: "bash",
        html: "html",
        css: "css",
        scss: "scss",
        json: "json",
        md: "markdown",
        xml: "xml",
        go: "go",
        yaml: "yaml",
        yml: "yaml"
    };
    return languageMap[extension] || "plaintext";
}
