<template>
  <template v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren)
      "
    >
      <app-link
        v-if="onlyOneChildValue.meta"
        :open="onlyOneChildValue.isOpen"
        :to="resolvePath(onlyOneChildValue.path)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChildValue.path)"
          popper-class="my-popper"
        >
          <svg-icon
            v-show="onlyOneChildValue.meta.icon"
            :icon-class="
              (activeTitle === onlyOneChildValue.meta.title
                ? onlyOneChildValue.meta.activeIcon
                : onlyOneChildValue.meta.icon) || ''
            "
            class-name="menu-icon"
          ></svg-icon>
          <template #title>
            <span class="menu-title-style">{{
              onlyOneChildValue.meta.title
            }}</span>
          </template>
        </el-menu-item>
      </app-link>
    </template>

    <el-sub-menu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      deprecated
      popper-class="my-popper"
    >
      <template v-if="item.meta" #title>
        <svg-icon
          v-if="item.meta && item.meta.icon"
          :icon-class="
            (activeTitle === item.meta.title
              ? item.meta.activeIcon
              : item.meta.icon) || ''
          "
          class-name="menu-icon"
        ></svg-icon>
        <span class="menu-title-style">{{ item.meta.title }}</span>
      </template>
      <!-- 
        :is-nest="true" 
        class="nest-menu"
      -->
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-sub-menu>
  </template>
</template>

<script setup>
import path from "path";
import { useRoute } from "vue-router";
import { isExternal } from "@/utils";
import AppLink from "./Link";
import { reactive, computed } from "vue";
const route = useRoute();
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  basePath: {
    type: String,
    default: ""
  },
  activeMenu: {
    type: String,
    default: ""
  }
});

let onlyOneChildValue = computed(() => onlyOneChild);
const activeTitle = computed(() => {
  return route?.matched[0]?.meta?.title;
});
let onlyOneChild = reactive({});
const hasOneShowingChild = (children = [], parent) => {
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false;
    } else {
      onlyOneChild = reactive(item);
      return true;
    }
  });
  if (showingChildren.length === 1) {
    return true;
  }

  if (showingChildren.length === 0) {
    onlyOneChild = reactive({ ...parent, path: "", noShowingChildren: true });
    return true;
  }

  return false;
};
const resolvePath = (routePath) => {
  if (isExternal(routePath)) {
    return routePath;
  }
  if (isExternal(props.basePath)) {
    return props.basePath;
  }
  return path.resolve(props.basePath, routePath);
};
</script>

<style lang="scss" scoped>
:deep().el-menu-item .el-menu-tooltip__trigger {
  width: auto;
}
:deep() .el-menu-item {
  border-radius: 12px;
}
.el-menu-item.is-active {
  font-weight: 500;
  background-color: $primary-primaryblue-20;
}
.el-menu-item:hover {
  background-color: $primary-primaryblue-20;
}
.menu-icon {
  width: 16px !important;
  height: 16px !important;
}
li .menu-title-style {
  margin-left: 10px;
}
.expand li .menu-title-style {
  margin-left: 10px;
  display: none;
}
</style>
<style lang="scss">
.sidebar-layout {
  --el-menu-level-padding: 16px;
  .el-sub-menu,
  .el-menu-item {
    margin-top: 6px;
  }
  .el-sub-menu.is-active .el-sub-menu__title {
    color: $neutral-neutral-90;
    font-weight: 500;
  }
  .el-sub-menu__title {
    padding-right: 20px;
    border-radius: 12px;
  }
  .el-sub-menu__title:hover {
    background-color: $primary-primaryblue-20 !important;
    border-radius: 12px;
  }
}
</style>
