import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bcaabac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    id: "app",
    "data-server-rendered": "true"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view)
    ]));
}
