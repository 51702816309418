<template>
  <div class="cloud-button-group">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.cloud-button-group {
  // display: inline-block;
  display: flex;
  align-items: center;
  :deep() .cl-button {
    position: relative;
  }
  :deep().cl-button + .cl-button {
    margin: 0;
  }
  :deep().cl-button.cl-button--text {
    margin: 0 10px;
  }
  :deep().cl-button.cl-button--text:first-child {
    margin-left: 0;
  }
  :deep().cl-button.cl-button--text:last-child {
    margin-right: 0;
  }
  :deep() .cl-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  :deep().cl-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :deep() .cl-button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  :deep() .cl-button::after {
    content: "";
    display: block;
    width: 1px; /* 竖线的宽度 */
    height: 12px; /* 竖线的高度 */
    opacity: 0.2;
    background-color: $brand-bobblue-1; /* 竖线的颜色 */
    position: absolute;
    right: 0;
    top: calc(50% - 6px);
  }
  :deep() .cl-button.cl-button--text::after {
    right: -10px;
  }
  :deep() .cl-button:last-child:after {
    display: none;
  }
}
</style>
