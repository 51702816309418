<template>
  <div
    class="cloud-avatar"
    :style="{
      '--avatar-font-size': baseSize + 'px',
      '--avatar-volume-size': baseSize * 2 + 'px',
      '--avatar-border-radius': borderRadius + 'px'
    }"
  >
    <div
      class="img-box"
      :style="{
        border: border ? '1px solid #fff' : 'none',
        background: 'center no-repeat url(' + getAvatar + ')',
        backgroundSize: 'cover'
      }"
    >
      {{ showNickname ? getName : "" }}
    </div>
    <span v-if="showName">{{ name }}</span>
  </div>
</template>
<script setup>
import { computed, watch, ref, reactive } from "vue";
import { useCounterStore } from "@/store/user";
const userStore = useCounterStore();
const userInfo = userStore.getUserInfo;

const props = defineProps({
  name: {
    type: String,
    default: ""
  },
  showName: {
    type: Boolean,
    default: true
  },
  border: {
    type: Boolean,
    default: false
  },
  // 8/16 10/20 12/24 16/32 20/40 34/68
  baseSize: {
    type: Number,
    default: 8
  },
  length: {
    type: Number,
    default: 1
  },
  borderRadius: {
    type: Number,
    default: 8
  },
  showNickname: {
    type: Boolean,
    default: true
  },
  avatar: {
    type: String,
    default: ""
  }
});

const getName = computed(() => {
  const name = props.name.trim();
  return props.length === 1
    ? name.slice(0, props.length).toUpperCase()
    : name.slice(0, props.length);
});

const getAvatar = computed(() => {
  const url = props.avatar
    ? props.avatar
    : "https://img.ebtech.com/avatars/default.png";
  return url;
});
</script>
<style scoped lang="scss">
$volume: var(--avatar-volume-size);
$fontSize: var(--avatar-font-size);
$borderRadius: var(--avatar-border-radius);
.cloud-avatar {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: $neutral-neutral-80;
  span {
    font-size: inherit;
    margin-left: 4px;
  }
  .img-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $volume;
    width: $volume;
    border-radius: $borderRadius;
    overflow: hidden;
    background: center no-repeat url("@/assets/img/avatar-2.png");
    background-size: 100% 100%;
    font-size: $fontSize;
    color: #fff;
  }
}
</style>
