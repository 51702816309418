import request from "@/utils/request";
export function readMessage(data) {
    return request({
        url: "/message/read",
        method: "POST",
        data,
    });
}
export function getMessageList(data) {
    return request({
        url: "/message/list",
        method: "GET",
        params: data,
    });
}
export function messageDetail(data) {
    return request({
        url: "/message/detail",
        method: "GET",
        params: data,
    });
}
export function deleteMessage(data) {
    return request({
        url: "/message/del",
        method: "POST",
        data,
    });
}
export function notReadMessage(data) {
    return request({
        url: "/message/unReadCount",
        method: "GET",
        params: data,
    });
}
