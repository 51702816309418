import request from "@/utils/request";
export function register(data) {
    return request({
        url: "/user/register",
        method: "POST",
        data
    });
}
export function sendSMS(data) {
    return request({
        url: "/user/sendSMS",
        method: "GET",
        params: data
    });
}
export function bindMFA() {
    return request({
        url: "/user/bindMFA",
        method: "GET"
    });
}
export function verifyMFA(data) {
    return request({
        url: "/user/verifyMFA",
        method: "GET",
        params: data
    });
}
export function login(data) {
    return request({
        url: "/user/login",
        method: "GET",
        params: data
    });
}
export function loginByPassword(data) {
    return request({
        url: "/user/loginByPassword",
        method: "GET",
        params: data
    });
}
export function loginByPhone(data) {
    return request({
        url: "/user/loginByPhone",
        method: "GET",
        params: data
    });
}
export function switchLogin(data) {
    return request({
        url: "user/switchLogin",
        method: "GET",
        params: data
    });
}
export function loginSub(data) {
    return request({
        url: "/user/loginSub",
        method: "GET",
        params: data
    });
}
export function refreshToken(data) {
    return request({
        url: "/user/refreshToken",
        method: "POST",
        data
    });
}
export function modifySubPassword(data) {
    return request({
        url: "/user/modifySubPassword",
        method: "POST",
        data
    });
}
export function modifyPassword(data) {
    return request({
        url: "/user/modifyPassword",
        method: "POST",
        data
    });
}
export function logout() {
    return request({
        url: "/user/logout",
        method: "GET"
    });
}
